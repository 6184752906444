/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { Button, Input, Form, FormGroup, Label, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from './style';
import { api } from './../../services/api';
import Loading from 'react-loading';

export function Register(): JSX.Element {
  const history = useHistory();

  const [cpf, setCPF] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  // const [birthdate, setBirthdate] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  // erros
  const [errorName, setErrorName] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPhone, setErrorPhone] = useState('');
  // const [errorBirthDate, setErrorBirthDate] = useState('');
  const [errorCPF, setErrorCPF] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorPasswordConfirmation, setErrorPasswordConfirmation] = useState('');
  const [errorTerms, setErrorTerms] = useState('');

  function onChangeHandle(): void {
    setTerms(!terms);
  }

  async function handleSubmitLogin(): Promise<void> {
    if (name.length < 1) {
      setErrorName('Nome obrigatório.');
    } else {
      setErrorName('');
    }
    if (email.length < 1) {
      setErrorEmail('Email obrigatório.');
    } else if (!email.includes('@')) {
      setErrorEmail('É necessário um email válido.');
    } else if (email.split('@')[1] === '') {
      setErrorEmail('É necessário um email válido.');
    } else {
      setErrorEmail('');
    }
    if (phone.length < 1) {
      setErrorPhone('Telefone obrigatório.');
    } else {
      setErrorPhone('');
    }
    // if (birthdate.length < 1) {
    //   setErrorBirthDate('Data de nascimento obrigatório.');
    // } else {
    //   setErrorBirthDate('');
    // }

    if (password.length < 1) {
      setErrorPassword('Senha obrigatória.');
    } else if (password !== passwordConfirmation) {
      setErrorPassword('É necessário que as senhas sejam iguais.');
    } else {
      setErrorPassword('');
    }
    if (passwordConfirmation.length < 1) {
      setErrorPasswordConfirmation('Confirmação de senha obrigatória.');
    } else if (password !== passwordConfirmation) {
      setErrorPasswordConfirmation('É necessário que as senhas sejam iguais.');
    } else {
      setErrorPasswordConfirmation('');
    }

    if (!terms) {
      setErrorTerms('É necessário aceitar os termos.');
    } else {
      setErrorTerms('');
    }
    if (cpf.length !== 14) {
      setErrorCPF('CPF obrigatório.');
    } else {
      setErrorCPF('');
    }
    // if (password === '') {
    //   setErrorPassword('Senha obrigatória.');
    // } else if (password.length < 8) {
    //   setErrorPassword('Mínimo 8 caracteres.');
    // } else {
    //   setErrorPassword('');
    // }

    if (cpf.length === 14 && password !== '' && password.length >= 8) {
      // signIn({ cpf, password });
    }
    if (
      name.length !== 0 &&
      email.length !== 0 &&
      // birthdate.length !== 0 &&
      cpf.length !== 0 &&
      password.length !== 0 &&
      passwordConfirmation.length !== 0 &&
      terms &&
      password === passwordConfirmation &&
      email.includes('@') &&
      email.split('@')[1].length > 1
    ) {
      setLoading(true);
      try {
        await api.post('/auth/register', {
          name,
          accept_use_terms: terms,
          email,
          cpf,
          password,
          phone,
        });
        toast('Cadastrado com sucesso!', {
          type: 'success',
        });
        setLoading(false);
        history.push('/login');
      } catch (error) {
        setLoading(false);

        toast('Erro interno contate o administrador do sistema', {
          type: 'error',
        });
      }
    }
  }
  useEffect(() => {
    localStorage.removeItem('inputValueCpf');
    localStorage.removeItem('inputValuePassword');
  }, []);

  function cpfMask(value: string): string {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }

  function phoneMask(value: string): string {
    if (value.length === 0) return '';
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d)/, '($1) $2');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');
    return value;
  }

  return (
    <div
      className="container"
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        minHeight: '100vh',
        // width: '100%',
      }}
    >
      <div
        className="mx-auto d-none d-md-block"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '50%',
        }}
      >
        <img src="/assets/IMAGE.png" alt="icone-amapa" width={'100%'} height={'auto'} />
      </div>
      <div
        className="col-sm"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '50%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',

            // display: 'flex',
            // flexDirection: 'column',
            // justifyContent: 'center',
            // alignItems: 'center',
            // height: '100%',
            // width: '100%',
            // margin: 'auto',
            // position: 'absolute',
          }}
        >
          <div
            style={{
              textAlign: 'right',
              justifyContent: 'right',
              marginBottom: 20,
              marginTop: 20,
            }}
          >
            <p style={{ fontSize: '9' }}>
              Já possui conta?{' '}
              <a style={{ textDecoration: 'none' }} href="/login">
                Entrar
              </a>
            </p>
          </div>
          <div style={{ marginBottom: 25, textAlign: 'left', justifyContent: 'left' }}>
            <h5>Cadastre-se</h5>
            {/* <span>Faça login para continuar</span> */}
          </div>

          <Form>
            <FormGroup>
              <Label
                for="name"
                style={{
                  color: errorName?.length > 1 ? '#E44B39' : '#2C2C2D',
                }}
              >
                Nome
              </Label>
              <Input
                style={{ borderColor: errorName?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                id="name"
                name="name"
                placeholder="Insira o nome"
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <text
                style={{
                  fontSize: 14,
                  color: '#E44B39',
                  marginBottom: 5,
                }}
              >
                {errorName}
              </text>
            </FormGroup>
            <FormGroup>
              <Label
                for="cpf"
                style={{
                  color: errorCPF?.length > 1 ? '#E44B39' : '#2C2C2D',
                }}
              >
                CPF
              </Label>
              <Input
                style={{ borderColor: errorCPF?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                id="exampleEmail"
                name="email"
                value={cpf}
                placeholder="000.000.000-00"
                type="text"
                onChange={(e) => {
                  setCPF(cpfMask(e.target.value));
                }}
              />
              <text
                style={{
                  fontSize: 14,
                  color: '#E44B39',
                  marginBottom: 5,
                }}
              >
                {errorCPF}
              </text>
            </FormGroup>
            <FormGroup>
              <Label
                for="exampleEmail"
                style={{
                  color: errorEmail?.length > 1 ? '#E44B39' : '#2C2C2D',
                }}
              >
                Email
              </Label>
              <Input
                style={{ borderColor: errorEmail?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                id="exampleEmail"
                name="email"
                placeholder="Insira seu email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <text
                style={{
                  fontSize: 14,
                  color: '#E44B39',
                  marginBottom: 5,
                }}
              >
                {errorEmail}
              </text>
            </FormGroup>
            <FormGroup>
              <Label
                for="phone"
                style={{
                  color: errorPhone?.length > 1 ? '#E44B39' : '#2C2C2D',
                }}
              >
                Telefone
              </Label>
              <Input
                style={{
                  borderColor: errorPhone?.length > 1 ? '#E44B39' : '#DEE2E6',
                }}
                id="phone"
                name="phone"
                placeholder="Insira um número para contato"
                type="tel"
                value={phone}
                maxLength={15}
                onChange={(e) => {
                  setPhone(phoneMask(e.target.value));
                }}
              />
              <text
                style={{
                  fontSize: 14,
                  color: '#E44B39',
                  marginBottom: 5,
                }}
              >
                {errorPhone}
              </text>
            </FormGroup>
            <FormGroup>
              <Label
                for="password"
                style={{
                  color: errorPassword?.length > 1 ? '#E44B39' : '#2C2C2D',
                }}
              >
                Senha
              </Label>
              <Input
                style={{ borderColor: errorPassword?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                id="password"
                name="password"
                placeholder="Insira a senha"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <text
                style={{
                  fontSize: 14,
                  color: '#E44B39',
                  marginBottom: 5,
                }}
              >
                {errorPassword}
              </text>
            </FormGroup>
            <FormGroup>
              <Label
                for="passwordConfirmation"
                style={{
                  color: errorPasswordConfirmation?.length > 1 ? '#E44B39' : '#2C2C2D',
                }}
              >
                Confirmar senha
              </Label>
              <Input
                style={{
                  borderColor: errorPasswordConfirmation?.length > 1 ? '#E44B39' : '#DEE2E6',
                }}
                id="passwordConfirmation"
                name="passwordConfirmation"
                placeholder="Confirme a senha"
                type="password"
                value={passwordConfirmation}
                onChange={(e) => {
                  setPasswordConfirmation(e.target.value);
                }}
              />
              <text
                style={{
                  fontSize: 14,
                  color: '#E44B39',
                  marginBottom: 5,
                }}
              >
                {errorPasswordConfirmation}
              </text>
            </FormGroup>

            <FormGroup check style={{ marginBottom: 10 }}>
              <Label check>
                <Input checked={terms} onChange={onChangeHandle} type="checkbox" /> Eu aceito os
                termos de uso e política de privacidade
              </Label>
              <br />
              <text
                style={{
                  fontSize: 14,
                  color: '#E44B39',
                  marginBottom: 5,
                }}
              >
                {errorTerms}
              </text>
            </FormGroup>
          </Form>
          <Button
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async () => {
              await handleSubmitLogin();
            }}
            disabled={loading}
            style={{ minWidth: 100, maxWidth: 100 }}
          >
            {loading ? <Spinner type="border" size={'sm'} color="light" /> : 'Cadastrar'}
          </Button>
        </div>
      </div>
    </div>
  );
}
