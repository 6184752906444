import React, {
  createContext,
  type ReactComponentElement,
  useCallback,
  useEffect,
  useState,
  type ReactNode,
} from 'react';
import { HiOutlineCalendar } from 'react-icons/hi';
import { type IconType } from 'react-icons/lib';

interface SideBarSelectedType {
  icon: IconType;
  title: string;
  to: string;
}

export interface SideBarSelectedContextData {
  sidebarSelectedItem: SideBarSelectedType;
  handleChangeSelected: (data: SideBarSelectedType) => void;
}

interface EventContextProviderProps {
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const SideBarSelectedContext = createContext({} as SideBarSelectedContextData);

export function SideBarSelectedContextProvider(props: EventContextProviderProps): JSX.Element {
  const [sidebarSelectedItem, setSidebarSelectedItem] = useState<SideBarSelectedType>({
    // @ts-expect-error
    icon: <HiOutlineCalendar />,
    title: 'Dashboard',
    to: '/dashboard',
  });

  const handleChangeSelected = useCallback((data: SideBarSelectedType) => {
    setSidebarSelectedItem(data);
  }, []);

  return (
    <SideBarSelectedContext.Provider
      value={{
        sidebarSelectedItem,
        handleChangeSelected,
      }}
    >
      <>{props.children}</>
    </SideBarSelectedContext.Provider>
  );
}
