import React from 'react';

const SearchBar = ({ placeholder, keyword, setKeyword, ...rest }): JSX.Element => {
  const BarStyling = {
    width: '80%',
    background: '#F2F1F9',
    padding: '0.5rem',
    borderRadius: 8,
    border: 'none',
  };
  return (
    <input
      style={BarStyling}
      key="random1"
      value={keyword}
      placeholder={placeholder}
      onChange={(e) => setKeyword(e.target.value)}
    />
  );
};

export default SearchBar;
