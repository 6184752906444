import React, { useEffect, useState } from 'react';
import { useAuth } from './../../../hooks/useAuth';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { ChatMessages } from '../chatMessages';
import { useAttendances } from '../../../hooks/useAttendances';
import { RiWechatFill } from 'react-icons/ri';
import { type ListAttendance } from '../../../context/attendances';
import { Scrollbars } from 'react-custom-scrollbars';
import { api } from '../../../services/api';
import { Spinner } from 'reactstrap';

interface Messages {
  id: number;
  text: string;
  contactUsId: number;
  is_org: boolean;
  read: boolean;
}

// interface ListAttendance {
//   id: number;
//   title: string;
//   userId: number;
//   closed: boolean;
//   category: string;
//   messages: Messages[];
// }

interface PropsChatList {
  listAttendances: ListAttendance[];
  category: string;
}

export function ChatList({ category, listAttendances }: PropsChatList): JSX.Element {
  const [scrolldown, setScrolldown] = useState(false);
  const [selected, setSelected] = useState<Messages[]>([]);
  const [userSelected, setUserSelected] = useState('');
  const [closed, setClosed] = useState(false);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const { messagesCurrent, setMessagesCurrentRenewFunction } = useAttendances();

  async function setMessages(messages: Messages[], item: boolean, user: string): Promise<void> {
    setLoadingLocal(true);
    try {
      const response = await api.get(`contact_us/read/${messages[0].contactUsId}`);
      setLoadingLocal(false);
    } catch (error) {}
    setClosed(item);
    setMessagesCurrentRenewFunction(messages);
    setSelected(messagesCurrent);
    setUserSelected(user);

    setLoadingLocal(false);
  }

  useEffect(() => {
    setScrolldown(true);
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      <div
        className="col-sm-4"
        style={{
          display: 'flex',
          flexDirection: 'column',
          // maxWidth: '500',
          // backgroundColor: '#F6F8FC',
          backgroundColor: '#FFF',
          height: '69vh',
          border: '1px solid rgba(0, 0, 0, 0.05)',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 50,
          }}
        >
          <div style={{ width: '80%' }}>
            {category === 'Elogio' && (
              <h4 style={{ textAlign: 'start', marginLeft: 30 }}>Elogios</h4>
            )}
            {category === 'Reclamação' && (
              <h4 style={{ textAlign: 'start', marginLeft: 30 }}>Reclamações</h4>
            )}
            {category === 'Sugestão' && (
              <h4 style={{ textAlign: 'start', marginLeft: 30 }}>Sugestões</h4>
            )}
          </div>
          <div style={{ display: 'flex', width: '10%', justifyContent: 'center' }}>
            {/* <FaPlus color="#0D6EFD" style={{ cursor: 'pointer' }} /> */}
          </div>
          <div style={{ display: 'flex', width: '10%', justifyContent: 'center' }}>
            {/* <FaSearch
              color="#0D6EFD"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises

              style={{ cursor: 'pointer' }}
            /> */}
          </div>
        </div>
        <Scrollbars style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
          {listAttendances?.map((item, i) => (
            <div
              key={item.id}
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                height: 90,
                paddingLeft: 20,

                borderBottom: '2px solid #F6F6F6',
                borderLeft:
                  messagesCurrent[0]?.id === item.messages[0].id ? '12px solid #003E5D' : '',
                backgroundColor:
                  messagesCurrent[0]?.id === item.messages[0].id
                    ? '#F6F8FC'
                    : i % 2 === 0
                    ? '#FFF'
                    : '#FFF',
                cursor: 'pointer',
                pointerEvents:
                  loadingLocal || messagesCurrent[0]?.id === item.messages[0].id ? 'none' : 'auto',
              }}
              onClick={() => {
                setMessages(item.messages, item.closed, item?.user.name)
                  .then(() => {})
                  .catch(() => {});
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '90%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <span>
                  <b>{item.title}</b>
                </span>
                {/* <span>{item.ultimaMensagem}</span> */}
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '10%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <span>{/* <b>{item.hora}</b> */}</span>
                <div
                  style={{
                    display: 'flex',
                    backgroundColor: '#DC3545',
                    borderRadius: 12,
                    width: 30,
                    justifyContent: 'center',
                    color: '#F6F6F6',
                  }}
                >
                  {messagesCurrent[0]?.id !== item.messages[0].id
                    ? item.messages.filter((item) => !item.read).length > 0
                      ? item.messages.filter((item) => !item.read).length
                      : ''
                    : ''}
                </div>
              </div>
            </div>
          ))}
        </Scrollbars>
      </div>
      {messagesCurrent?.length > 0 ? (
        <ChatMessages
          scrollDown={scrolldown}
          messages={messagesCurrent}
          closed={closed}
          user={userSelected}
          loadingLocal={loadingLocal}
        />
      ) : loadingLocal ? (
        <div
          className="col-sm-8"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Spinner type="border" size={'sm'} color="primary" />
        </div>
      ) : (
        <div
          className="col-sm-8"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <RiWechatFill size={95} color={'#005675'} />
          <span>Selecione um dos atendimentos à esquerda.</span>
        </div>
      )}
    </div>
  );
}
