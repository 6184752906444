import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label,
  Spinner,
} from 'reactstrap';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';

interface newsAmapa {
  idNoticia: string;
  nt_id_cat: string;
  nt_slug: string;
  dataPub: string;
  titulo: string;
  nt_chamada: string;
  foto: string;
  nt_legenda: string;
  assunto: string;
}

interface PropsEvent {
  isOpen: boolean;
  handleIsOpen: () => void;
  item: newsAmapa;
}

export function ModalNews({ isOpen, handleIsOpen, item }: PropsEvent): JSX.Element {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [description, setDescription] = useState('');
  const [publishDate, setPublishDate] = useState('');
  const [idApiNews, setIdApiNews] = useState('');
  const [image, setImage] = useState('');
  const [caption, setCaption] = useState('');

  function convertToPlain(html): string {
    // Create a new div element
    const tempDivElement = document.createElement('div');

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

    setTitle(item?.titulo);

    setPublishDate(item?.dataPub);

    setIdApiNews(item?.idNoticia);
    if (item?.assunto) {
      setDescription(convertToPlain(item?.assunto));
    }
    setImage(item?.foto);
    setSubtitle(item?.nt_chamada);
  }, [item, isOpen]);

  // errors

  function close(): void {
    setTitle('');
    setDescription('');
    setIdApiNews('');
    setImage('');
    setSubtitle('');
    setPublishDate('');
    handleIsOpen();
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={close} size={'lg'}>
        <ModalHeader toggle={close}>Visualizar notícia</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">Imagem</Label>
              {image?.length > 1 && (
                <div style={{ display: 'flex', justifyContent: 'center', maxHeight: 500 }}>
                  <img src={image} alt={'cupom'} width={'50%'} height={'auto'} />
                </div>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Título</Label>
              <Input id="exampleEmail" name="text" type="textarea" value={title} disabled />
            </FormGroup>

            <FormGroup>
              <Label for="exampleEmail">Subtítulo</Label>
              <Input
                id="exampleEmail"
                name="email"
                // placeholder="Insira o nome do local do evento"
                type="textarea"
                disabled
                value={subtitle}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Data da publicação</Label>
              <Input id="exampleEmail" name="text" type="text" value={publishDate} disabled />
            </FormGroup>

            <FormGroup>
              <Label for="exampleEmail">Descrição</Label>
              <Input
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                id="exampleEmail"
                name="email"
                disabled
                placeholder="Insira a descrição do evento"
                type="textarea"
              />
              {/* <div style={{ backgroundColor: '#E9ECEF', padding: 5 }}>
                {ReactHtmlParser(item?.nt_texto)}
              </div> */}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
}
