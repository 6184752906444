import React from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { Login } from './../notAuthenticatedScreens/login/login';
import { Register } from './../notAuthenticatedScreens/register/index';
import { ResetPassword } from './../notAuthenticatedScreens/resetPassword/index';
import { ForgotPassword } from './../notAuthenticatedScreens/forgotPassword/index';
import { PageError } from './../notAuthenticatedScreens/components/pageError';
import { DeleteInformationScreen } from './../notAuthenticatedScreens/deleteInformationScreen/index';
import { App } from './../notAuthenticatedScreens/app/index';

const SignRoutes = ({ path: string, ...rest }): JSX.Element => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgotPassword" component={ForgotPassword} />
        <Route exact path="/resetPassword/:token" component={ResetPassword} />
        <Route exact path="/deleteInformation" component={DeleteInformationScreen} />
        <Route exact path="/aplicativo" component={App} />
        <Route path="*" component={PageError} />
      </Switch>
    </Router>
  );
};

export default SignRoutes;
