import React, { useEffect, useState, useMemo } from 'react';
// import styled from 'styled-components';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import 'react-calendar/dist/Calendar.css';
import { Calendar } from 'react-calendar';
import { FaPlus, FaUserCheck } from 'react-icons/fa';
import { CalendarLocal } from '../components/CalendarLocal';
import { ModalAddEvent } from '../components/modalAddEvent/index';
import { useEvent } from './../../hooks/useEvent';
import Loading from 'react-loading';

export function WarningNoProfileDefined(): JSX.Element {
  // <div style={{ width: '100%', backgroundColor: '#F2F2F2' }}>
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#F2F2F2',
        height: '88vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 50,
          border: '1px solid rgba(0, 0, 0, 0.05)',
          borderRadius: 12,
          backgroundColor: '#fff',
        }}
      >
        <FaUserCheck size={125} color={'#0060AE'} />
        <br />
        <br />
        <p style={{ fontSize: 18 }}>
          Aguarde a definição de perfil do seu usuário, consulte o administrador do sistema para a
          definição de perfil.
        </p>
        <p style={{ fontSize: 18 }}>
          <b>
            Por favor, saia e entre novamente no sistema para verificar se seu perfil já foi
            definido.
          </b>
        </p>
      </div>
    </div>
  );
}
