import React from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { Schedule } from './../authenticatedScreens/schedule/schedule';
import { News } from './../authenticatedScreens/news/news';
import { PageError } from './../authenticatedScreens/components/pageError';
import SideBar from './../components/sidebar/Sidebar';
import { Chat } from '../authenticatedScreens/chat';
import { UserPermissions } from '../authenticatedScreens/userPermissions';
import { Ticket } from '../authenticatedScreens/ticket';
import { Dashboard } from '../authenticatedScreens/dashboard';
import { PrizeDraw } from '../authenticatedScreens/prizeDraw';
import { Company } from '../authenticatedScreens/company';

const InternalRoutes = ({ path: string, ...rest }): JSX.Element => {
  return (
    <Router>
      <SideBar />
      <Switch>
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/schedule" component={Schedule} />
        <Route exact path="/news" component={News} />
        <Route exact path="/chat" component={Chat} />
        <Route exact path="/userPermissions" component={UserPermissions} />
        <Route exact path="/ticket" component={Ticket} />
        <Route exact path="/prizeDraw" component={PrizeDraw} />
        <Route exact path="/company" component={Company} />
        <Route path="*" component={PageError} />
      </Switch>
    </Router>
  );
};

export default InternalRoutes;
