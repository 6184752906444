import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label,
  Spinner,
} from 'reactstrap';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';

interface newsLocal {
  id: string;
  idApiNews: string;
  publishDate: string;
  title: string;
  subtitle: string;
  image: string;
  caption: string;
  description: string;
}

interface PropsEvent {
  isOpen: boolean;
  handleIsOpen: () => void;
  getAllLocal: () => Promise<void>;
  item: newsLocal;
}

export function ModalEditNews({
  isOpen,
  handleIsOpen,
  item,
  getAllLocal,
}: PropsEvent): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [description, setDescription] = useState('');
  const [publishDate, setPublishDate] = useState('');
  const [idApiNews, setIdApiNews] = useState('');
  const [image, setImage] = useState('');
  const [caption, setCaption] = useState('');
  function convertToPlain(html): string {
    // Create a new div element
    const tempDivElement = document.createElement('div');

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

    setTitle(item?.title);

    setPublishDate(String(moment(item?.publishDate).format('DD/MM/YYYY')));

    setIdApiNews(item?.id);
    if (item?.description) {
      setDescription(convertToPlain(item?.description));
    }
    setImage(item?.image);
    setSubtitle(item?.subtitle);
  }, [item, isOpen]);

  // errors

  function close(): void {
    setTitle('');
    setDescription('');
    setIdApiNews('');
    setImage('');
    setSubtitle('');
    setPublishDate('');
    handleIsOpen();
  }

  async function updateNews(): Promise<void> {
    setLoading(true);
    try {
      await api.patch(`news/${item.id}`, {
        title,
        description,
        subtitle,
        // caption,
      });
      close();
      toast('Notícia atualizada com sucesso!', { type: 'success' });
      setLoading(false);
      await getAllLocal();
    } catch (error) {
      toast('Não foi possivel atualizar a notícia', { type: 'error' });
      setLoading(false);
    }
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={close} size={'lg'}>
        <ModalHeader toggle={close}>Visualizar notícia</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">Imagem</Label>
              {image?.length > 1 && (
                <div style={{ display: 'flex', justifyContent: 'center', maxHeight: 500 }}>
                  <img src={`${image}`} alt={caption} width={'50%'} height={'auto'} />
                </div>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Título</Label>
              <Input
                id="exampleEmail"
                name="text"
                type="textarea"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </FormGroup>

            <FormGroup>
              <Label for="exampleEmail">Subtítulo</Label>
              <Input
                id="exampleEmail"
                name="email"
                placeholder="Subtítulo"
                type="textarea"
                value={subtitle}
                onChange={(e) => {
                  setSubtitle(e.target.value);
                }}
              />
            </FormGroup>
            {/* <FormGroup>
              <Label for="exampleEmail">Data da publicação</Label>
              <Input id="exampleEmail" name="text" type="text" value={publishDate} />
            </FormGroup> */}

            <FormGroup>
              <Label for="exampleEmail">Descrição</Label>
              <Input
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                id="exampleEmail"
                name="email"
                placeholder="Insira a descrição do evento"
                type="textarea"
              />
              {/* <div style={{ backgroundColor: '#E9ECEF', padding: 5 }}>
                {ReactHtmlParser(item?.nt_texto)}
              </div> */}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async () => {
              await updateNews();
            }}
            style={{ minWidth: 120 }}
            disabled={loading}
          >
            {loading ? <Spinner type="border" size={'sm'} color="light" /> : 'Atualizar Notícia'}
          </Button>{' '}
          <Button color="secondary" onClick={close}>
            Cancelar
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}
