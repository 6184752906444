import React, { useEffect, useState, useMemo, useCallback } from 'react';
// import styled from 'styled-components';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Badge,
  Input,
} from 'reactstrap';
import 'react-calendar/dist/Calendar.css';
import { Calendar } from 'react-calendar';
import {
  FaArrowCircleRight,
  FaArrowRight,
  FaEdit,
  FaEye,
  FaPlus,
  FaSearch,
  FaTrash,
} from 'react-icons/fa';
import { CalendarLocal } from '../components/CalendarLocal';
import { ModalAddEvent } from '../components/modalAddEvent/index';
import { useEvent } from './../../hooks/useEvent';
import Loading from 'react-loading';
import SearchBar from '../components/searchBar';
import { apiAp } from './../../services/apiAmapá';
import { api } from './../../services/api';
import axios from 'axios';
import { TablePadrão } from './../components/table/index';
import { ModalEditUser } from '../components/modalEditUser';
import { toast } from 'react-toastify';

interface usersType {
  id: string;
  name: string;
  email: string;
  phone: string;
  profile: string;
  cpf: string;
}

interface usersTypeEdit {
  id: string;
  name: string;
  profile: string;
  cpf: string;
  phone: string;
  email: string;
}

export function UserPermissions(): JSX.Element {
  const [inputAdded, setInputAdded] = useState('');
  const [inputNotAdded, setInputNotAdded] = useState('');
  const [usersDefault, setUsersDefault] = useState<usersType[]>([]);
  const [users, setUsers] = useState<usersType[]>([]);
  const [selected, setSelected] = useState<usersTypeEdit>();

  const [loading, setLoading] = useState(false);
  const [loadingLocal, setLoadingLocal] = useState(true);

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [septemberDays, setSeptemberDays] = useState<Date[]>([]);
  const [isOpenModalUser, setIsOpenModalUser] = useState(false);
  const [inputNameOrCPF, setInputNameOrCPF] = useState('');

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  function handleIsOpenModalUser(): void {
    setIsOpenModalUser(!isOpenModalUser);
  }

  function localUserHandleOpenModal(item: usersTypeEdit): void {
    setSelected(item);
    handleIsOpenModalUser();
  }

  // const getAllUsers = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const resonse = await api.get('user', {});

  //     setUsersDefault(resonse.data);
  //     setUsers(resonse.data);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log(error, 'teste');
  //   }
  // }, []);
  async function findUsersPerPage(targetPage: number): Promise<void> {
    setLoading(true);
    setInputNameOrCPF('');
    try {
      const response = await api.get('user', {
        params: {
          page: targetPage,
          perPage,
        },
      });

      setUsers(response.data.users);

      setUsersDefault(response.data.users);

      setPage(response.data.page);
      setTotalPages(response.data.totalPage);
      setLoading(false);
      setLoadingLocal(false);
    } catch (err) {
      console.log(err);
      const message = 'Ocorreu um erro ao buscar os dados usuario';
      setLoading(false);
      toast(message, {
        type: 'error',
      });
    }
  }

  useEffect(() => {
    void findUsersPerPage(1);
  }, [perPage]);

  async function filter(): Promise<void> {
    if (inputNameOrCPF.length === 0) {
      void findUsersPerPage(1);
    }
    if (inputNameOrCPF.length > 3) {
      try {
        setLoadingLocal(true);
        const response = await api.get(`user/search/${inputNameOrCPF}`);
        setUsers(response.data);
        setLoadingLocal(false);
      } catch (error) {
        console.log(error);
        setLoadingLocal(false);
      }
    }
  }
  useEffect(() => {
    void filter();
  }, [inputNameOrCPF]);

  function cpfMask(value: string): string {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }

  const thead = ['Nome', 'CPF', 'Tipo Perfil', 'Ações'];
  const headWidth = ['50%', '20%', '20%', '10%'];
  // <div style={{ width: '100%', backgroundColor: '#F2F2F2' }}>
  return (
    <div style={{ width: '100%', backgroundColor: '#FFF', minHeight: '90vh' }}>
      {loading ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 25 }}
        >
          <Loading width={30} color={'#005675'} type={'spin'} />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginLeft: 50,
            marginTop: 40,
            marginRight: 50,
          }}
        >
          <div className="col-sm-4" style={{ display: 'flex', marginBottom: 15, marginTop: 15 }}>
            <h4>Usuários e Permissões</h4>
          </div>
          <div style={{ border: '1px solid rgba(0, 0, 0, 0.05)', borderRadius: 8 }}>
            <div style={{ display: 'flex', marginLeft: 40 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Mostrar</span>
                <Input
                  type="select"
                  onChange={(e) => {
                    setPerPage(Number(e.target.value));
                  }}
                  style={{ width: 80, marginRight: 8, marginLeft: 8 }}
                  value={perPage}
                >
                  <option key={'10'} value={'10'}>
                    10
                  </option>
                  <option key={'20'} value={'20'}>
                    20
                  </option>
                  <option key={'30'} value={'30'}>
                    30
                  </option>
                  <option key={'50'} value={'50'}>
                    50
                  </option>
                </Input>
                <span>registros</span>
              </div>
              <div
                className="col-sm-4"
                style={{
                  display: 'flex',
                  marginBottom: 15,
                  marginTop: 55,
                  marginLeft: 40,
                  flexDirection: 'column',
                }}
              >
                <Input
                  value={inputNameOrCPF}
                  onChange={(e) => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    setInputNameOrCPF(
                      e.target.value.match(/^[0-9.-]*$/) ? cpfMask(e.target.value) : e.target.value,
                    );
                  }}
                  placeholder="Pesquise por nome ou CPF"
                />
                <p style={{ fontSize: 14, marginTop: 5 }}>{'(Insira pelo menos 4 caracteres)*'}</p>
              </div>
            </div>
            <br />
            {loadingLocal ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <Loading width={30} color={'#005675'} type={'spin'} />
              </div>
            ) : users?.length === 0 && !loadingLocal ? (
              <div className="c-text-danger d-flex justify-content-center mt-20">
                Nenhum usuário encontrado.
              </div>
            ) : (
              <TablePadrão
                page={page}
                totalPage={totalPages}
                thead={thead}
                headWidth={headWidth}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                handleChangePage={findUsersPerPage}
              >
                {users?.map((item, i) => (
                  <tr
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: i % 2 === 0 ? '#F5F9FF' : '#FFF',
                      padding: 10,
                    }}
                    key={item.id}
                  >
                    <td
                      style={{
                        width: '50%',
                        justifyContent: 'start',
                        textAlign: 'start',
                        marginLeft: 10,
                      }}
                    >
                      {item.name}
                    </td>
                    <td style={{ width: '20%', textAlign: 'center' }}>{item.cpf}</td>
                    <td style={{ width: '20%', textAlign: 'center' }}>{item.profile}</td>
                    <td style={{ width: '10%', textAlign: 'center' }}>
                      <FaEdit
                        color="#0D6EFD"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          localUserHandleOpenModal(item);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </TablePadrão>
            )}
          </div>
        </div>
      )}
      {selected && (
        <ModalEditUser
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          getAllUsers={findUsersPerPage}
          user={selected}
          handleIsOpen={handleIsOpenModalUser}
          isOpen={isOpenModalUser}
        />
      )}
    </div>
  );
}

// const WrapperContainer = styled.div`
//   width: 100%;

//   .content {
//     width: 100%;

//     & div {
//       display: flex;
//     }

//     select:focus {
//       outline: none;
//     }

//     textarea:focus {
//       outline: none;
//     }

//     & input {
//       width: 100%;
//     }

//     & input:focus {
//       outline: none !important;
//     }

//     .inputfile {
//       width: 0.1px;
//       height: 0.1px;
//       opacity: 0;
//       overflow: hidden;
//       position: absolute;
//       z-index: -1;
//     }
//     .inputfile:focus + label {
//       outline: 1px dotted #000;
//       outline: -webkit-focus-ring-color auto 5px;
//     }
//     .inputfile + label * {
//       pointer-events: none;
//     }
//   }
// `;
