import React, { useEffect, useState, useMemo, useCallback } from 'react';
// import styled from 'styled-components';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Badge,
  Input,
} from 'reactstrap';
import 'react-calendar/dist/Calendar.css';
import { Calendar } from 'react-calendar';
import {
  FaArrowCircleRight,
  FaArrowRight,
  FaCheck,
  FaEdit,
  FaEye,
  FaPlus,
  FaSearch,
  FaTrash,
} from 'react-icons/fa';
import { CalendarLocal } from '../components/CalendarLocal';
import { ModalAddEvent } from '../components/modalAddEvent/index';
import { useEvent } from './../../hooks/useEvent';
import Loading from 'react-loading';
import SearchBar from '../components/searchBar';
import { apiAp } from './../../services/apiAmapá';
import { api } from './../../services/api';
import axios from 'axios';
import { TablePadrão } from './../components/table/index';
import { toast } from 'react-toastify';
import { ModalWinners } from '../components/modalWinners';

interface usersType {
  id: string;
  name: string;
  phone: string;
  profile: string;
  cpf: string;
}

interface ticketTypeEdit {
  id: string;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  status: string;
  quantity: string;
  latitude: string;
  longitude: string;
  imageURL: string;
  user: usersType;
}

export function PrizeDraw(): JSX.Element {
  const [inputAdded, setInputAdded] = useState('');
  const [inputNotAdded, setInputNotAdded] = useState('');
  const [ticketsDefault, setTicketsDefault] = useState<ticketTypeEdit[]>([]);
  const [tickets, setTickets] = useState<ticketTypeEdit[]>([]);
  const [selected, setSelected] = useState<ticketTypeEdit>();
  const [firstTab, setFirstTab] = useState(true);
  const [secondTab, setSecondTab] = useState(false);
  const [thirdTab, setThirdTab] = useState(false);
  const [fourthTab, setFourthTab] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingLocal, setLoadingLocal] = useState(true);

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [septemberDays, setSeptemberDays] = useState<Date[]>([]);
  const [isOpenModalUser, setIsOpenModalUser] = useState(false);
  const [inputNameOrCPF, setInputNameOrCPF] = useState(null);

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  function handleIsOpenModalUser(): void {
    setIsOpenModalUser(!isOpenModalUser);
  }

  function localUserHandleOpenModal(item: ticketTypeEdit): void {
    setSelected(item);
    handleIsOpenModalUser();
  }

  async function findTicketsPerPage(targetPage: number): Promise<void> {
    setLoading(true);
    setInputNameOrCPF('');
    try {
      let response;

      if (currentStatus) {
        response = await api.get('ticket', {
          params: {
            page: targetPage,
            perPage,
            paginated: true,
            status: currentStatus,
          },
        });
      } else {
        response = await api.get('ticket', {
          params: {
            page: targetPage,
            perPage,
            paginated: true,
          },
        });
      }
      setTickets(response.data.tickets);

      setTicketsDefault(response.data.tickets);

      setPage(response.data.page);
      setTotalPages(response.data.totalPage);
      setLoading(false);
      setLoadingLocal(false);
    } catch (err) {
      console.log(err);
      const message = 'Ocorreu um erro ao buscar os dados do cupom';
      setLoading(false);
      toast(message, {
        type: 'error',
      });
    }
  }

  useEffect(() => {
    findTicketsPerPage(1)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  }, [perPage]);

  // useEffect(() => {
  //   setLoadingLocal(false);
  // }, [tickets]);

  async function filter(): Promise<void> {
    setLoadingLocal(true);

    if (inputNameOrCPF?.length === 0) {
      await findTicketsPerPage(1);
    }
    if (inputNameOrCPF?.length > 3) {
      try {
        const response = await api.get(`ticket/search/${String(inputNameOrCPF)}`);
        setTickets(response.data);
        setLoadingLocal(false);
      } catch (error) {
        console.log(error);
        setLoadingLocal(false);
      }
    }
  }
  useEffect(() => {
    if (inputNameOrCPF !== null) {
      filter()
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }, [inputNameOrCPF]);

  const thead = ['Título cupom', 'Nome usuário', 'CPF', 'Status', 'Ações'];
  const headWidth = ['25%', '25%', '20%', '20%', '10%'];

  useEffect(() => {
    if (currentStatus !== null) {
      findTicketsPerPage(1)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }, [currentStatus]);

  async function sortear(id): Promise<void> {
    try {
      setLoading(true);

      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const response = await api.post(`ticket/prize/${id}`);

      toast('Sorteio realizado com sucesso!', {
        type: 'success',
      });
      setLoading(false);
    } catch (error) {
      // @ts-expect-error
      if (error?.response?.data?.error?.message === 'No favorite found') {
        toast('Este sorteio não foi favoritado por nenhum usuário', {
          type: 'error',
        });
        // @ts-expect-error
      } else if (error?.response?.data?.error?.message === 'Todos usuários já sorteados') {
        toast('Todos os usuários desse sorteio já foram sorteados', {
          type: 'error',
        });
      } else {
        console.log(error);
        toast('Não foi possível realizar o sorteio', {
          type: 'error',
        });
      }

      setLoading(false);
    }
  }

  return (
    <div style={{ width: '100%', backgroundColor: '#FFF', minHeight: '90vh' }}>
      {loading ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 25 }}
        >
          <Loading width={30} color={'#005675'} type={'spin'} />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginLeft: 50,
            marginTop: 40,
            marginRight: 50,
          }}
        >
          <div className="col-sm-4" style={{ display: 'flex', marginBottom: 15, marginTop: 15 }}>
            <h4>Cupons</h4>
          </div>
          {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                display: 'flex',
                backgroundColor: firstTab ? '#FFF' : '#F5F5F5',
                border: '1px solid rgba(0, 0, 0, 0.05)',
                width: 100,
                borderBottom: 'none',
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                height: 30,
                justifyContent: 'center',
                alignItems: 'center',
                position: firstTab ? 'relative' : 'static',
                top: 1,
                cursor: 'pointer',
              }}
              onClick={() => {
                void handleChangeTabs(1);
              }}
            >
              <span>Todos</span>
            </div>
            <div
              style={{
                display: 'flex',
                backgroundColor: secondTab ? '#FFF' : '#F5F5F5',
                border: '1px solid rgba(0, 0, 0, 0.05)',
                width: 100,
                borderBottom: 'none',
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                height: 30,
                justifyContent: 'center',
                alignItems: 'center',
                position: secondTab ? 'relative' : 'static',
                top: 1,
                cursor: 'pointer',
              }}
              onClick={() => {
                void handleChangeTabs(2);
              }}
            >
              <span>Pendentes</span>
            </div>
            <div
              style={{
                display: 'flex',
                backgroundColor: thirdTab ? '#FFF' : '#F5F5F5',
                border: '1px solid rgba(0, 0, 0, 0.05)',
                width: 100,
                borderBottom: 'none',
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                height: 30,
                justifyContent: 'center',
                alignItems: 'center',
                position: thirdTab ? 'relative' : 'static',
                top: 1,
                cursor: 'pointer',
              }}
              onClick={() => {
                void handleChangeTabs(3);
              }}
            >
              <span>Aprovados</span>
            </div>
            <div
              style={{
                display: 'flex',
                backgroundColor: fourthTab ? '#FFF' : '#F5F5F5',
                border: '1px solid rgba(0, 0, 0, 0.05)',
                width: 100,
                borderBottom: 'none',
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                height: 30,
                justifyContent: 'center',
                alignItems: 'center',
                position: fourthTab ? 'relative' : 'static',
                top: 1,
                cursor: 'pointer',
              }}
              onClick={() => {
                void handleChangeTabs(4);
              }}
            >
              <span>Negados</span>
            </div>
          </div> */}

          <div style={{ border: '1px solid rgba(0, 0, 0, 0.05)', borderRadius: 8 }}>
            <div style={{ display: 'flex', marginLeft: 40 }}>
              {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Mostrar</span>
                <Input
                  type="select"
                  onChange={(e) => {
                    setPerPage(Number(e.target.value));
                  }}
                  style={{ width: 80, marginRight: 8, marginLeft: 8 }}
                  value={perPage}
                >
                  <option key={'10'} value={'10'}>
                    10
                  </option>
                  <option key={'20'} value={'20'}>
                    20
                  </option>
                  <option key={'30'} value={'30'}>
                    30
                  </option>
                </Input>
                <span>registros</span>
              </div> */}
              <div
                className="col-sm-4"
                style={{
                  display: 'flex',
                  marginBottom: 15,
                  marginTop: 35,
                  marginLeft: 40,
                  flexDirection: 'column',
                }}
              >
                <Input
                  value={inputNameOrCPF}
                  onChange={(e) => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    setInputNameOrCPF(e.target.value);
                  }}
                  placeholder="Pesquise pelo nome do cupom"
                />
                <p style={{ fontSize: 14, marginTop: 5 }}>{'(Insira pelo menos 4 caracteres)*'}</p>
              </div>
            </div>
            <br />
            {loadingLocal ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <Loading width={30} color={'#005675'} type={'spin'} />
              </div>
            ) : tickets?.length === 0 && !loadingLocal ? (
              <div className="c-text-danger d-flex justify-content-center mt-20">
                Nenhum cupom encontrado.
              </div>
            ) : (
              <TablePadrão
                page={page}
                totalPage={totalPages}
                thead={thead}
                headWidth={headWidth}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                handleChangePage={findTicketsPerPage}
              >
                {tickets?.map((item, i) => (
                  <tr
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: i % 2 === 0 ? '#F5F9FF' : '#FFF',
                      padding: 10,
                    }}
                    key={item?.id}
                  >
                    <td
                      style={{
                        width: '25%',
                        justifyContent: 'start',
                        textAlign: 'start',
                        marginLeft: 10,
                      }}
                    >
                      {item?.name}
                    </td>
                    <td
                      style={{
                        width: '25%',
                        justifyContent: 'start',
                        textAlign: 'center',
                      }}
                    >
                      {item?.user?.name}
                    </td>
                    <td style={{ width: '20%', textAlign: 'center' }}>{item?.user?.cpf}</td>
                    <td style={{ width: '20%', textAlign: 'center' }}>
                      {item?.status === 'Pendente' && (
                        <Badge className="col-sm-5" color="warning">
                          {item?.status}
                        </Badge>
                      )}
                      {item?.status === 'Negado' && (
                        <Badge className="col-sm-5" color="danger">
                          {item?.status}
                        </Badge>
                      )}
                      {item?.status === 'Aprovado' && (
                        <Badge className="col-sm-5" color="success">
                          {item?.status}
                        </Badge>
                      )}
                    </td>
                    <td style={{ width: '10%', textAlign: 'center' }}>
                      <FaEye
                        color="#0D6EFD"
                        style={{ cursor: 'pointer', marginRight: 10 }}
                        onClick={() => {
                          localUserHandleOpenModal(item);
                        }}
                        title="Visualizar ganhadores"
                      />

                      <FaCheck
                        style={{ pointerEvents: loading ? 'none' : 'auto', cursor: 'pointer' }}
                        onClick={() => {
                          sortear(item.id)
                            .then(() => {})
                            .catch(() => {});
                        }}
                        color="#0D6EFD"
                        title="Sortear"
                      />
                    </td>
                  </tr>
                ))}
              </TablePadrão>
            )}
          </div>
        </div>
      )}
      {selected && (
        <ModalWinners
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          ticket={selected}
          handleIsOpen={handleIsOpenModalUser}
          isOpen={isOpenModalUser}
        />
      )}
    </div>
  );
}

// const WrapperContainer = styled.div`
//   width: 100%;

//   .content {
//     width: 100%;

//     & div {
//       display: flex;
//     }

//     select:focus {
//       outline: none;
//     }

//     textarea:focus {
//       outline: none;
//     }

//     & input {
//       width: 100%;
//     }

//     & input:focus {
//       outline: none !important;
//     }

//     .inputfile {
//       width: 0.1px;
//       height: 0.1px;
//       opacity: 0;
//       overflow: hidden;
//       position: absolute;
//       z-index: -1;
//     }
//     .inputfile:focus + label {
//       outline: 1px dotted #000;
//       outline: -webkit-focus-ring-color auto 5px;
//     }
//     .inputfile + label * {
//       pointer-events: none;
//     }
//   }
// `;
