import React from 'react';
import styled from 'styled-components';
interface PropsTablePadrão {
  thead: React.ReactNode[];
  headWidth: Array<string | number>;
  children: React.ReactNode;
}

export function TableNoPaginate({ children, thead, headWidth }: PropsTablePadrão): JSX.Element {
  const width = headWidth || '40%';
  const widthID = '10%';

  return (
    <div
      className="col-sm-12"
      style={{
        display: 'flex',
        overflow: 'auto',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <table style={{ width: '100%', marginBottom: 50 }}>
        <thead className="col-sm-12" style={{ display: 'flex', width: '100%' }}>
          <tr style={{ display: 'flex', width: '100%' }}>
            {thead.map((item, i) => (
              <th
                key={i}
                scope="col"
                style={{
                  width: headWidth[i],
                  maxWidth: headWidth[i],
                  display: 'flex',
                  justifyContent: i === 0 ? 'start' : 'center',
                  alignItems: 'center',
                  padding: 10,
                  paddingLeft: i === 0 ? 20 : 0,
                }}
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* exemplo */}
          {/* {tbody.map((item, i) => (
              <Tr key={i}>
                <Td width="8%">{item.id}</Td>
                <Td>{item.nome}</Td>
                <Td>
                  <AiOutlineEye size={30} color="rgba(15, 169, 88, 1)" />
                </Td>
              </Tr>
            ))} */}
          {children}
        </tbody>
      </table>
    </div>
  );
}

interface ITd {
  width?: number | string;
  none?: boolean;
}
interface ITr {
  disable?: boolean;
}

export const Td = styled.td<ITd>`
  width: ${(props) => props.width || '40%'};
  max-width: ${(props) => props.width || '40%'};
  display: ${(props) => (props.none ? 'none' : 'flex')};
  justify-content: center;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;
export const Tr = styled.tr<ITr>`
  display: ${(props) => (props.disable ? 'none' : 'flex')};
  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const TRr = styled.tr`
  display: flex;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;
