import React, { useEffect, useState } from 'react';
// import styled from 'styled-components';
import { Badge } from 'reactstrap';
import moment from 'moment';
import { Row } from './style';
import 'moment/locale/pt-br';
import { useEvent } from './../../hooks/useEvent';
import { ModalEditEvent } from './modalEditEvent/index';

export function CalendarLocal({ month, indexDaysVisibles, greater, year }): JSX.Element {
  const [isOpenModalRound, setIsOpenModalRound] = useState(false);
  const [dateObjectState, setDateObjectState] = useState(moment());
  const [dateObjectStateSecond, setDateObjectStateSecond] = useState(moment());
  const [id, setId] = useState(0);
  const [loading, setLoading] = useState(true);

  const { eventList } = useEvent();

  useEffect(() => {
    setDateObjectState(dateObjectState.set('month', Number(month)));
    setDateObjectState(dateObjectState.set('year', year));
    setDateObjectState(dateObjectState.locale('pt-br'));
    setLoading(false);
    // console.log(month, indexDaysVisibles, 'lp');
  }, []);
  function firstDayOfMonth(): string {
    const dateObject = dateObjectState;
    const firstDay = moment(dateObject).startOf('month').format('d'); // Day of week 0...1..5...6
    return firstDay;
  }

  function daysInMonthFunction(): number {
    return dateObjectState.daysInMonth();
  }

  function currentDayFunction(): string | undefined {
    return dateObjectState.format('D');
  }

  const weekdayshort = moment.weekdaysShort();
  const weekdayshortname = weekdayshort.map((day) => {
    return <th key={day}>{day.toUpperCase()}</th>;
  });
  const blanks: JSX.Element[] = [];
  for (let i = 0; i < Number(firstDayOfMonth()); i++) {
    blanks.push(<td className="calendar-day empty">{''}</td>);
  }
  const daysInMonth: JSX.Element[] = [];
  for (let d = 1; d <= daysInMonthFunction(); d++) {
    const currentDay = d === Number(currentDayFunction()) ? 'today' : '';
    daysInMonth.push(
      currentDay.length > 0 ? (
        <td
          key={d}
          style={{
            backgroundColor:
              greater === true ? (d <= 28 ? '#EBEBE4' : '#fff') : d > 8 ? '#EBEBE4' : '#fff',
          }}
        >
          <Badge color="primary">{d}</Badge>
        </td>
      ) : (
        <td
          key={d}
          style={{
            backgroundColor:
              greater === true ? (d <= 28 ? '#EBEBE4' : '#fff') : d > 8 ? '#EBEBE4' : '#fff',
          }}
        >
          <span
            style={{
              color:
                greater === true ? (d <= 28 ? '#9E9E9E' : 'black') : d > 8 ? '#9E9E9E' : 'black',
            }}
          >
            {d}
          </span>
        </td>
      ),
    );

    for (let index = 0; index < eventList?.length; index++) {
      if (new Date(eventList[index].scheduleDate).getDate() === d) {
        daysInMonth.pop();
        daysInMonth.push(
          currentDay.length > 0 ? (
            <td key={d} style={{ maxWidth: 90 }}>
              <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Badge
                  color="primary"
                  style={{ height: '20', width: 40, fontSize: 15, marginBottom: 20 }}
                >
                  {d}
                </Badge>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'start',
                    alignItems: 'start',
                    justifyContent: 'end',
                    height: '100%',
                  }}
                >
                  {eventList?.map(
                    (item, i) =>
                      new Date(item.scheduleDate).getDate() === Number(d) && (
                        <tr key={i}>
                          <Badge
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              handleModalEdit(Number(item.id));
                            }}
                            color="success"
                          >
                            {item?.title?.length > 20
                              ? `${item?.title?.slice(0, 20)}...`
                              : item?.title}
                          </Badge>
                        </tr>
                      ),
                  )}
                </div>
              </div>
            </td>
          ) : (
            <td key={d} style={{ maxWidth: 90 }}>
              <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <span color="primary" style={{ height: '20', marginBottom: 5 }}>
                  {d}
                </span>
                <div
                  style={{
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    alignContent: 'start',
                    alignItems: 'start',
                    justifyContent: 'end',
                  }}
                >
                  {eventList?.map(
                    (item, i) =>
                      new Date(item.scheduleDate).getDate() === Number(d) && (
                        <tr key={i}>
                          <Badge
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              handleModalEdit(Number(item.id));
                            }}
                            color="success"
                          >
                            {item?.title?.length > 20
                              ? `${item?.title?.slice(0, 20)}...`
                              : item?.title}
                          </Badge>
                        </tr>
                      ),
                  )}
                </div>
              </div>
            </td>
          ),
        );
      }
    }
  }
  const totalSlots = [...blanks, ...daysInMonth];
  const rows: JSX.Element[] = [];
  let cells: JSX.Element[] = [];

  totalSlots.forEach((row, i) => {
    if (i % 7 !== 0) {
      cells.push(row);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      rows.push(cells);

      cells = [];
      cells.push(row);
    }
    if (i === totalSlots.length - 1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      rows.push(cells);
    }
  });

  const daysinmonth = rows.map((d, i) => {
    return greater
      ? Number(i) > Number(indexDaysVisibles) && <tr key={Number(i)}>{d}</tr>
      : Number(i) < Number(indexDaysVisibles) && <tr key={Number(i)}>{d}</tr>;
  });

  function handleModalEdit(i: number): void {
    setId(i);
    handleIsOpenModalRound();
  }

  function handleIsOpenModalRound(): void {
    setIsOpenModalRound(!isOpenModalRound);
  }

  return (
    <>
      {!loading && (
        <div className="calendar-date">
          <Row bordered>
            <thead>
              <tr style={{ textTransform: 'capitalize' }}>{weekdayshortname}</tr>
            </thead>
            <tbody>{daysinmonth}</tbody>
          </Row>
          {id !== 0 && (
            <ModalEditEvent
              isOpen={isOpenModalRound}
              id={id}
              year={year}
              handleIsOpen={handleIsOpenModalRound}
            />
          )}
        </div>
      )}
    </>
  );
}
