import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {ToastContainer} from 'react-toastify'
import {Main} from './routes/main'
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import "leaflet/dist/leaflet.css";
import {AuthContextProvider} from './context/auth'
import {SocketContextProvider} from './context/socket'
import {EventContextProvider} from './context/event'
import {SideBarSelectedContextProvider} from './context/sideBarSelected'
import { AllAttendancesContextProvider } from './context/attendances';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    
      <AuthContextProvider><AllAttendancesContextProvider>
        <SocketContextProvider>
          
          
            
          <SideBarSelectedContextProvider>
          <EventContextProvider>
            <ToastContainer/>
            <Main />
          </EventContextProvider>
          </SideBarSelectedContextProvider>
          
          
          
        </SocketContextProvider>
        </AllAttendancesContextProvider>
      </AuthContextProvider>
   
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
