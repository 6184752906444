import React from 'react';
import { Redirect } from 'react-router-dom';

export function PageError(): JSX.Element {
  return (
    <div>
      <Redirect to={'/dashboard'} />
    </div>
  );
}
