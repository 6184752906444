import { useContext } from 'react';
import {
  SideBarSelectedContext,
  type SideBarSelectedContextData,
} from './../context/sideBarSelected';

export function useSideBarSelected(): SideBarSelectedContextData {
  const context = useContext(SideBarSelectedContext);

  if (context === null || context === undefined) {
    throw new Error('useEvent must be used within an eventProvider');
  }

  return context;
}
