/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Loading from 'react-loading';
import InternalRoutes from './internalRoutes';
import NoProfileDefinedRoutes from './noProfileDefinedRoutes';
import SignRoutes from './signRoutes';
import NavBar from './../components/navbar/NavBar';
import { useAuth } from '../hooks/useAuth';

const PrivateRoute = ({ component: Component, ...rest }): JSX.Element => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <div className="d-flex" style={{ width: '95%' }}>
          <Component {...props} />
        </div>
      )}
    />
  );
};

const OpenRoutes = ({ component: Component, ...rest }): JSX.Element => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <div className="d-flex" style={{ width: '95%' }}>
          {<Component {...props} />}
        </div>
      )}
    />
  );
};

const PrivateRouteNoProfileDefined = ({ component: Component, ...rest }): JSX.Element => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <div className="d-flex" style={{ width: '95%' }}>
          {<Component {...props} />}
        </div>
      )}
    />
  );
};

export const Main = (): JSX.Element => {
  const { signed, loading, user } = useAuth();

  return (
    <>
      {signed && <NavBar />}

      <Router>
        <Switch>
          {signed ? (
            user?.profile === 'Cidadão' ? (
              <NoProfileDefinedRoutes path="/" component={PrivateRouteNoProfileDefined} />
            ) : (
              <div style={{ display: 'flex', margin: 0 }}>
                <InternalRoutes path="/" component={PrivateRoute} />
              </div>
            )
          ) : (
            <SignRoutes path="/" component={OpenRoutes} />
          )}
        </Switch>
      </Router>
    </>
  );
  // <Router>
  //   <Switch>
  //   </Switch>
  // </Router>
};
