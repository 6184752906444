import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { FormGroup, Form, Input, Label, Button, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Container } from './style';
import { api } from './../../services/api';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { HiOutlineLockClosed, HiOutlineLockOpen, HiOutlineUserCircle } from 'react-icons/hi';

export const ResetPassword = (props): JSX.Element => {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordSuccessfullyUpdated, setPasswordSuccessfullyUpdated] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorPasswordConfirmation, setErrorPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);

  // const { signIn, loading } = useAuth();
  async function handleSubmitLogin(): Promise<void> {
    if (email.length < 1) {
      setErrorEmail('Email obrigatório.');
    } else if (!email.includes('@')) {
      setErrorEmail('É necessário um email válido.');
    } else if (email.split('@')[1] === '') {
      setErrorEmail('É necessário um email válido.');
    } else {
      setErrorEmail('');
    }

    if (password.length < 1) {
      setErrorPassword('Senha obrigatória.');
    } else if (password !== passwordConfirmation) {
      setErrorPassword('É necessário que as senhas sejam iguais.');
    } else {
      setErrorPassword('');
    }
    if (passwordConfirmation.length < 1) {
      setErrorPasswordConfirmation('Confirmação de senha obrigatória.');
    } else if (password !== passwordConfirmation) {
      setErrorPasswordConfirmation('É necessário que as senhas sejam iguais.');
    } else {
      setErrorPasswordConfirmation('');
    }
    // if (cpf.length === 14 && password !== '' && password.length >= 8) {
    if (
      email.length !== 0 &&
      password.length !== 0 &&
      passwordConfirmation.length !== 0 &&
      password === passwordConfirmation &&
      email.includes('@') &&
      email.split('@')[1].length > 1
    ) {
      await resetPassword();
    }
  }
  // useEffect(() => {
  //   setCPF(localStorage.getItem('inputValueCpf') as unknown as string);
  //   setPassword(localStorage.getItem('inputValuePassword') as unknown as string);
  // }, []);

  async function resetPassword(): Promise<void> {
    try {
      setLoading(true);
      const resetPassword = await api.post('auth/reset-password', {
        email,
        newPassword: password,
        resetToken: props?.match?.params?.token,
      });
      toast('Senha alterada com sucesso!', { type: 'success' });
      setLoading(false);
      setPasswordSuccessfullyUpdated(true);
      // history.push('/login');
    } catch (error) {
      setLoading(false);
      // @ts-expect-error error
      if (error?.response?.status === 400) {
        toast('Email não encontrado!', { type: 'error' });
        console.log(error);
      } else {
        toast('Um erro foi encontrado, contate o administrador do sistema, ou tente novamente', {
          type: 'error',
        });
        console.log(error);
      }
    }
  }

  return (
    <div style={{ backgroundColor: '#FFF', margin: 'auto' }}>
      {passwordSuccessfullyUpdated ? (
        <Container>
          <div className="login-form">
            <div
              style={{
                justifyContent: 'center',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                  margin: 'auto',
                  position: 'absolute',
                  backgroundColor: '#F0EFF5',
                }}
              >
                <div
                  className="content"
                  style={{
                    border: '1px solid rgba(0, 0, 0, 0.05)',
                    padding: 50,
                    borderRadius: 8,
                    backgroundColor: '#fff',
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
                    <HiOutlineLockClosed size={150} color={'#005675'} />
                  </div>
                  <h4>Processo de redefinição de senha completo.</h4>
                </div>
              </div>
            </div>
          </div>
        </Container>
      ) : (
        <Container>
          <div className="login-form">
            <div
              style={{
                justifyContent: 'center',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                  margin: 'auto',
                  position: 'absolute',
                }}
              >
                <div className="content">
                  <Form style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <FormGroup>
                      <p style={{ textAlign: 'center', fontSize: 17, width: '100%' }}>
                        Preencha os campos para redefinição de senha.
                      </p>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        style={{
                          fontWeight: 'bold',
                          color: errorEmail?.length > 1 ? '#E44B39' : '#2C2C2D',
                        }}
                      >
                        Email
                      </Label>
                      <Input
                        style={{ borderColor: errorEmail?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                        id="exampleEmail"
                        name="email"
                        value={email}
                        placeholder="Insira seu email"
                        type="text"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <text
                        style={{
                          fontSize: 14,
                          color: '#E44B39',
                          marginBottom: 5,
                        }}
                      >
                        {errorEmail}
                      </text>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        style={{
                          fontWeight: 'bold',
                          color: errorPassword?.length > 1 ? '#E44B39' : '#2C2C2D',
                        }}
                      >
                        Nova senha
                      </Label>
                      <Input
                        style={{ borderColor: errorPassword?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                        id="exampleEmail"
                        name="email"
                        value={password}
                        placeholder="Insira a nova senha"
                        type="password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                      <text
                        style={{
                          fontSize: 14,
                          color: '#E44B39',
                          marginBottom: 5,
                        }}
                      >
                        {errorPassword}
                      </text>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        style={{
                          fontWeight: 'bold',
                          color: errorPasswordConfirmation?.length > 1 ? '#E44B39' : '#2C2C2D',
                        }}
                      >
                        Confirmar nova senha
                      </Label>
                      <Input
                        style={{
                          borderColor:
                            errorPasswordConfirmation?.length > 1 ? '#E44B39' : '#DEE2E6',
                        }}
                        id="exampleEmail"
                        name="email"
                        value={passwordConfirmation}
                        placeholder="Confirme a nova senha"
                        type="password"
                        onChange={(e) => {
                          setPasswordConfirmation(e.target.value);
                        }}
                      />
                      <text
                        style={{
                          fontSize: 14,
                          color: '#E44B39',
                          marginBottom: 5,
                        }}
                      >
                        {errorPasswordConfirmation}
                      </text>
                    </FormGroup>

                    <Button
                      disabled={loading}
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={async () => {
                        await handleSubmitLogin();
                      }}
                      style={{ width: '100%' }}
                      color="success"
                      size="md"
                    >
                      {loading ? <Spinner type="border" size={'sm'} color="light" /> : 'Enviar'}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};
