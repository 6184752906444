import {useContext} from 'react'
import {AuthContext,type AuthContextData} from './../context/auth'

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (context === null || context === undefined) {
    throw new Error('useAuth must be used within an authProvider');
  }

  return context;
}