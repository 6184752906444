import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label,
  Spinner,
} from 'reactstrap';
import { DatePicker } from 'reactstrap-date-picker';
import LocationPicker from 'react-leaflet-location-picker';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import { useEvent } from './../../../hooks/useEvent';
import { MapContainer } from 'react-leaflet';
import { Map } from './../map';

const DefaultLocation = { lat: -0.04268524821259019, lng: -51.108055437415 };
const DefaultZoom = 18;

interface Event {
  id: string;
  title: string;
  description: string;
  latitude: string;
  longitude: string;
  scheduleDate: Date;
  location: string;
  startTime: Date;
  endTime: Date;
  deleted_at: Date | null;
  deleted: false;
  createdAt: Date;
  updatedAt: Date;
}

interface PropsEvent {
  isOpen: boolean;
  handleIsOpen: () => void;
  action: () => void;
  title: string;
}

export function ModalWarning({ isOpen, handleIsOpen, title, action }: PropsEvent): JSX.Element {
  return (
    <div>
      <Modal isOpen={isOpen} toggle={handleIsOpen} size={'md'}>
        <ModalHeader toggle={handleIsOpen}>{title}</ModalHeader>

        <ModalFooter>
          <Button
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={() => {
              action();
            }}
            style={{ minWidth: 120 }}
            disabled={!isOpen}
          >
            Sim
          </Button>{' '}
          <Button color="secondary" onClick={handleIsOpen}>
            Cancelar
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}
