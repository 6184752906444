import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label,
  Spinner,
} from 'reactstrap';
import { api } from '../../../services/api';
import { DatePicker } from 'reactstrap-date-picker';
import { toast } from 'react-toastify';
import { useAuth } from './../../../hooks/useAuth';
import LocationPicker from 'react-leaflet-location-picker';
import moment from 'moment';
import { MapContainer } from 'react-leaflet';
import { Map } from './../map';
import { TableNoPaginate } from './../tableNoPaginate/index';

interface User {
  id: string;
  name: string;
  phone: string;
  profile: string;
  cpf: string;
}

interface Ticket {
  id: string;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  status: string;
  quantity: string;
  latitude: string;
  longitude: string;
  imageURL: string;
  user: User;
}

interface PropsEvent {
  isOpen: boolean;
  handleIsOpen: () => void;
  ticket: Ticket;
}

export function ModalWinners({ isOpen, handleIsOpen, ticket }: PropsEvent): JSX.Element {
  const [sorteados, setSorteados] = useState([]);

  useEffect(() => {
    if (isOpen) {
      getSorteados()
        .then(() => {})
        .catch(() => {});
    }
  }, [ticket, isOpen]);

  async function getSorteados(): Promise<void> {
    try {
      const response = await api.get(`/ticket/prizes/${ticket.id}`);
      setSorteados(response.data);
    } catch (error) {
      console.log(error);
      toast('Não foi possível obter a lista de sorteados', {
        type: 'error',
      });
    }
  }

  function close(): void {
    handleIsOpen();
  }
  const teste = [{ id: 1, nome: 'teste1', email: 'teste', celular: 'teste' }];

  const thead = ['Nome', 'Email', 'Celular'];
  const headWidth = ['50%', '25%', '25%'];

  return (
    <div>
      <Modal isOpen={isOpen} toggle={close} size={'xl'}>
        <ModalHeader toggle={close}>Sorteados</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              {sorteados?.length > 0 ? (
                <TableNoPaginate thead={thead} headWidth={headWidth}>
                  {sorteados?.map((item, i) => (
                    <>
                      <tr
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          backgroundColor: i % 2 === 0 ? '#F5F9FF' : '#FFF',
                          padding: 10,
                        }}
                        key={item?.id}
                      >
                        <td
                          style={{
                            width: '50%',
                            justifyContent: 'start',
                            textAlign: 'start',
                            marginLeft: 10,
                          }}
                        >
                          {item?.name}
                        </td>
                        <td
                          style={{
                            width: '25%',
                            justifyContent: 'center',
                            textAlign: 'center',
                            marginLeft: 10,
                          }}
                        >
                          {item?.email}
                        </td>
                        <td
                          style={{
                            width: '25%',
                            justifyContent: 'center',
                            textAlign: 'center',
                            marginLeft: 10,
                          }}
                        >
                          {item?.phone}
                        </td>
                      </tr>
                    </>
                  ))}
                </TableNoPaginate>
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <span>Sem ganhadores</span>
                </div>
              )}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={close}>
            fechar
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}
