import styled from 'styled-components';
import { Card } from 'reactstrap';

export const CardContainer = styled(Card)`
  @media (max-width: 1350px) {
    .title {
      height: 35px;
    }
  }

  @media (max-width: 450px) {
    .col-sm-4 {
      flex-direction: column;
    }
    .col-sm-12 {
      flex-direction: column;
    }
  }
`;

export const Container = styled.div`
  @media (max-width: 533px) {
    flex-direction: column;
  }
`;
