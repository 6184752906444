import React from 'react';
import {
  HiOutlineCalendar,
  HiUserGroup,
  HiNewspaper,
  HiDocumentReport,
  HiChat,
  HiTicket,
  HiOutlineTicket,
  HiOutlineChartPie,
  HiGift,
  HiIdentification,
} from 'react-icons/hi';
import { useAuth } from '../../hooks/useAuth';

export const admin = {
  title: 'Administrador',
  functions: [
    {
      icon: <HiOutlineChartPie />,
      title: 'Dashboard',
      to: '/dashboard',
    },
    {
      icon: <HiOutlineCalendar />,
      title: 'Eventos',
      to: '/schedule',
    },
    {
      icon: <HiNewspaper />,
      title: 'Notícias',
      to: '/news',
    },
    {
      icon: <HiChat />,
      title: 'Fale conosco',
      to: '/chat',
    },
    {
      icon: <HiUserGroup />,
      title: 'Usuários e Permissões',
      to: '/userPermissions',
    },
    {
      icon: <HiOutlineTicket />,
      title: 'Cupons',
      to: '/ticket',
    },
    {
      icon: <HiGift />,
      title: 'Sorteio',
      to: '/prizeDraw',
    },
    {
      icon: <HiIdentification />,
      title: 'Empresas',
      to: '/company',
    },
    // {
    //   icon: <HiDocumentReport />,
    //   title: 'Relatórios',
    //   to: '/schedule',
    // },
  ],
};

export const organizador = {
  title: 'Organizador',
  functions: [
    {
      icon: <HiOutlineCalendar />,
      title: 'Eventos',
      to: '/schedule',
    },
    {
      icon: <HiNewspaper />,
      title: 'Notícias',
      to: '/news',
    },
    {
      icon: <HiChat />,
      title: 'Fale conosco',
      to: '/chat',
    },
  ],
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useSidebarList() {
  const { user } = useAuth();
  if (user?.profile === 'Cidadão/Administrador') {
    return admin;
  } else {
    return organizador;
  }
}
