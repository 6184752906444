import React, { useMemo, useCallback } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

interface propTypes {
  currentPage: number;
  totalPages: number;
  handleChangePage: (page: number) => Promise<void>;
}

export const PaginationView = ({
  currentPage,
  totalPages,
  handleChangePage,
}: propTypes): JSX.Element => {
  const prev = useMemo(() => {
    const calc = Number(currentPage) - Number(1);

    if (calc <= 0) return Number(1);
    return calc;
  }, [currentPage]);

  const next = useMemo(() => {
    const calc = Number(currentPage) + Number(1);

    if (calc > Number(totalPages)) return Number(totalPages);

    return calc;
  }, [Number(currentPage), Number(totalPages)]);

  const calculateInterval = useMemo(
    () => Number(totalPages) - Number(currentPage),
    [Number(currentPage), Number(totalPages)],
  );

  const buttonPrevDisable = useMemo(
    () => prev === Number(currentPage),
    [Number(currentPage), prev],
  );

  const buttonNextDisable = useMemo(
    () => Number(next) === Number(currentPage),
    [Number(currentPage), Number(next)],
  );

  const onChangePage = useCallback(
    async (page: number) => {
      if (Number(page) === 0) return;
      if (Number(page) > Number(totalPages)) return;

      await handleChangePage(Number(page));
    },
    [handleChangePage, Number(totalPages)],
  );

  return (
    <Pagination aria-label="Page navigation example">
      <PaginationItem
        disabled={buttonPrevDisable}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={async () => {
          await onChangePage(Number(currentPage) - Number(1));
        }}
      >
        <PaginationLink previous />
      </PaginationItem>

      {Number(currentPage) > 2 && (
        <>
          <PaginationItem>
            <PaginationLink
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={async () => {
                await onChangePage(Number(1));
              }}
            >
              1
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        </>
      )}

      {!buttonPrevDisable && (
        <PaginationItem>
          <PaginationLink
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async () => {
              await onChangePage(Number(prev));
            }}
          >
            {prev}
          </PaginationLink>
        </PaginationItem>
      )}
      <PaginationItem active onClick={() => {}}>
        <PaginationLink>{Number(currentPage)}</PaginationLink>
      </PaginationItem>

      {!buttonNextDisable && (
        <PaginationItem>
          <PaginationLink
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async () => {
              await onChangePage(Number(next));
            }}
          >
            {next}
          </PaginationLink>
        </PaginationItem>
      )}
      {calculateInterval > 2 && (
        <>
          <PaginationItem>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={async () => {
                await onChangePage(Number(totalPages));
              }}
            >
              {Number(totalPages)}
            </PaginationLink>
          </PaginationItem>
        </>
      )}
      <PaginationItem
        disabled={buttonNextDisable}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={async () => {
          await onChangePage(Number(currentPage) + Number(1));
        }}
      >
        <PaginationLink next />
      </PaginationItem>
    </Pagination>
  );
};
