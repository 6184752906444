import React, { createContext, useEffect, useState, type ReactNode } from 'react';

import { ToastContainer } from 'react-toastify';
import { connect, type Socket } from 'socket.io-client';
import { useAttendances } from './../hooks/useAttendances';

export interface SocketContextData {
  socketConnection: Socket;
}

interface SocketContextProviderProps {
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const SocketContext = createContext({} as SocketContextData);

export function SocketContextProvider(props: SocketContextProviderProps): JSX.Element {
  const [socketConnection, setSocketConnection] = useState<Socket>();
  const { handleNewUnreadMessage, newMessage, getAllAttendances } = useAttendances();
  useEffect(() => {
    const socket = connect(process.env.REACT_APP_BASE_URl);
    setSocketConnection(socket);
  }, []);
  socketConnection?.off('message')?.on('message', (message) => {
    try {
      handleNewUnreadMessage(message.contactUsId, message);
      // newMessage(message);
    } catch (error) {
      console.log(error);
    }
  });

  socketConnection?.off('readMessage')?.on('readMessage', (message) => {
    try {
      getAllAttendances(false)
        .then(() => {})
        .catch(() => {});
      // newMessage(message);
    } catch (error) {
      console.log(error);
    }
  });
  return (
    <SocketContext.Provider
      value={{
        socketConnection,
      }}
    >
      <>
        <ToastContainer />
        {props.children}
      </>
    </SocketContext.Provider>
  );
}
