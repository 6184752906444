import { useContext } from 'react';
import { AllAttendancesContext, type AllAttendancesContextData } from './../context/attendances';

export function useAttendances(): AllAttendancesContextData {
  const context = useContext(AllAttendancesContext);

  if (context === null || context === undefined) {
    throw new Error('useAttendances must be used within an attendancesProvider');
  }

  return context;
}
