import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label,
  Spinner,
} from 'reactstrap';
import { DatePicker } from 'reactstrap-date-picker';
import LocationPicker from 'react-leaflet-location-picker';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from './../../../hooks/useAuth';

interface User {
  id: string;
  name: string;
  phone: string;
  profile: string;
  cpf: string;
  email: string;
}

interface PropsEvent {
  isOpen: boolean;
  handleIsOpen: () => void;
  user: User;
  getAllUsers: (page: number) => Promise<void>;
}

export function ModalEditUser({
  isOpen,
  handleIsOpen,
  user,
  getAllUsers,
}: PropsEvent): JSX.Element {
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [loading, setLoading] = useState(false);
  // errors
  const [errorProfile, setErrorProfile] = useState('');
  const { updateMyProfile } = useAuth();

  useEffect(() => {
    setCpf(user.cpf);
    setName(user.name);
    setProfile(user.profile);
    setNumber(user.phone);
    setEmail(user.email);
  }, [user, isOpen]);

  async function updateUserProfile(): Promise<void> {
    if (profile.length < 0 || profile === 'default') {
      setErrorProfile('Campo obrigatório.');
    } else {
      setErrorProfile('');
    }

    if (profile.length > 1 && profile !== 'default') {
      setLoading(true);
      const userLocal = localStorage.getItem('@expo:user') as unknown as string;
      const userFormatted = JSON.parse(userLocal) as User;

      try {
        await api.patch(`user/${user?.id}`, {
          profile,
        });
        close();
        toast('Perfil atualizado com sucesso!', { type: 'success' });
        await getAllUsers(1);
        if (userFormatted.id === user?.id) {
          updateMyProfile(profile);
          window.location.reload();
        }
        setLoading(false);
      } catch (error) {
        toast('Não foi possivel atualizar o perfil', { type: 'error' });
        setLoading(false);
      }
    }
  }

  function close(): void {
    setErrorProfile('');
    setCpf('');
    setName('');
    setEmail('');
    setProfile('');
    handleIsOpen();
  }

  // async function deleteEventLocal(): Promise<void> {
  //   const r = window.confirm(`Deseja remover o evento "${title}"?`);
  //   if (!r) return;
  //   try {
  //     // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
  //     const response = await deleteEvent(id);
  //     toast('Evento removido com sucesso!', { type: 'success' });
  //   } catch (error) {
  //     toast('Não foi possivel remover o evento', { type: 'error' });
  //   }
  // }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={close} size={'lg'}>
        <ModalHeader toggle={close}>Editar perfil</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">Nome do usuário</Label>
              <Input value={name} disabled />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">CPF do usuário</Label>
              <Input value={cpf} disabled />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Número para contato do usuário</Label>
              <Input value={number} disabled />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Email do usuário</Label>
              <Input value={email} disabled />
            </FormGroup>
            <FormGroup>
              <Label
                style={{
                  color: errorProfile?.length > 1 ? '#E44B39' : '#2C2C2D',
                }}
                for="exampleEmail"
              >
                Perfil
              </Label>
              <Input
                style={{ borderColor: errorProfile?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                type="select"
                name="select"
                id="exampleSelect"
                value={profile}
                onChange={(e) => {
                  setProfile(e.target.value);
                }}
              >
                <option key={'default'} value={'default'}>
                  Selecione o perfil
                </option>
                <option key={'citizen'} value={'Cidadão'}>
                  Cidadão
                </option>
                <option key={'citizen/manager'} value={'Cidadão/Organizador'}>
                  Cidadão/Organizador
                </option>
                <option key={'citizen/adm'} value={'Cidadão/Administrador'}>
                  Cidadão/Administrador
                </option>
              </Input>

              <text
                style={{
                  fontSize: 14,
                  color: '#E44B39',
                  marginBottom: 5,
                }}
              >
                {errorProfile}
              </text>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async () => {
              await updateUserProfile();
            }}
            style={{ minWidth: 120 }}
            disabled={loading}
          >
            {loading ? <Spinner type="border" size={'sm'} color="light" /> : 'Atualizar perfil'}
          </Button>{' '}
          <Button color="secondary" onClick={close}>
            Cancelar
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}
