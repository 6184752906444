import React, { useEffect, useState, useMemo } from 'react';
// import styled from 'styled-components';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Dropdown,
  Input,
} from 'reactstrap';
import { Row, Icon } from './style';
import 'react-calendar/dist/Calendar.css';
import { Calendar } from 'react-calendar';
import { FaPlus } from 'react-icons/fa';
import { CalendarLocal } from '../components/CalendarLocal';
import { ModalAddEvent } from '../components/modalAddEvent/index';
import { useEvent } from './../../hooks/useEvent';
import Loading from 'react-loading';
import { FilterYear } from '../components/filterYear';

export function Schedule(): JSX.Element {
  const { loading, getAll, setCurrentYear, currentYear } = useEvent();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [septemberDays, setSeptemberDays] = useState<Date[]>([]);
  const [isOpenModalRound, setIsOpenModalRound] = useState(false);

  const [eventList, setEventList] = useState([]);

  function handleIsOpenModalRound(): void {
    setIsOpenModalRound(!isOpenModalRound);
  }

  useEffect(() => {
    void getAll(currentYear);
  }, [currentYear]);

  // <div style={{ width: '100%', backgroundColor: '#F2F2F2' }}>
  return (
    <div style={{ width: '100%', backgroundColor: '#FFF' }}>
      {loading ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 25 }}
        >
          <Loading width={30} color={'#005675'} type={'spin'} />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              backgroundColor: 'gray',
              justifyContent: 'end',
            }}
          >
            {/* <div style={{ justifyContent: 'end', alignItems: 'end', margin: 20 }}>
            <div>
              <Button
                onClick={() => {
                  signOutLocal();
                }}
                color="warning"
              >
                <span style={{ color: '#fff' }}>Sair</span>
              </Button>
            </div>
          </div> */}
          </div>
          <div
            style={{
              margin: 25,
              maxWidth: '100%',
              display: 'flex',
              justifyContent: currentYear === '2024' ? 'space-between' : 'flex-end',
              height: 60,
            }}
          >
            <div style={{ width: 250, display: currentYear === '2023' && 'none' }}>
              <Icon
                onClick={() => {
                  handleIsOpenModalRound();
                }}
                color="primary"
              >
                <span>
                  <FaPlus size={14} />
                  {'   '} Adicionar evento
                </span>
              </Icon>
            </div>
            {/* <FilterYear currentYear={currentYear} setCurrentYear={setCurrentYear} /> */}

            {/* <Calendar/> */}
          </div>
          {currentYear === '2024' ? (
            <>
              <div style={{ margin: 25 }}>
                <div>
                  <h5>
                    <b>Agosto, 2024</b>
                  </h5>
                </div>
                <CalendarLocal year={2024} month={7} indexDaysVisibles={4} greater={true} />
              </div>
              <div style={{ margin: 25 }}>
                <div>
                  <h5>
                    <b>Setembro, 2024</b>
                  </h5>
                </div>
                <CalendarLocal year={2024} month={8} indexDaysVisibles={3} greater={false} />
              </div>
            </>
          ) : (
            <>
              <div style={{ margin: 25 }}>
                <div>
                  <h5>
                    <b>Setembro, 2023</b>
                  </h5>
                </div>
                <CalendarLocal year={2023} month={8} indexDaysVisibles={4} greater={true} />
              </div>
              <div style={{ margin: 25 }}>
                <div>
                  <h5>
                    <b>Outubro, 2023</b>
                  </h5>
                </div>
                <CalendarLocal year={2023} month={9} indexDaysVisibles={3} greater={false} />
              </div>
            </>
          )}
        </div>
      )}

      {isOpenModalRound && (
        <ModalAddEvent isOpen={isOpenModalRound} handleIsOpen={handleIsOpenModalRound} />
      )}
    </div>
  );
}

// const WrapperContainer = styled.div`
//   width: 100%;

//   .content {
//     width: 100%;

//     & div {
//       display: flex;
//     }

//     select:focus {
//       outline: none;
//     }

//     textarea:focus {
//       outline: none;
//     }

//     & input {
//       width: 100%;
//     }

//     & input:focus {
//       outline: none !important;
//     }

//     .inputfile {
//       width: 0.1px;
//       height: 0.1px;
//       opacity: 0;
//       overflow: hidden;
//       position: absolute;
//       z-index: -1;
//     }
//     .inputfile:focus + label {
//       outline: 1px dotted #000;
//       outline: -webkit-focus-ring-color auto 5px;
//     }
//     .inputfile + label * {
//       pointer-events: none;
//     }
//   }
// `;
