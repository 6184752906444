import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label,
  Spinner,
} from 'reactstrap';
import { DatePicker } from 'reactstrap-date-picker';
import LocationPicker from 'react-leaflet-location-picker';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from './../../../hooks/useAuth';
import { FaArrowCircleRight, FaPlus, FaSearch } from 'react-icons/fa';
import Loading from 'react-loading';
import { Scrollbars } from 'react-custom-scrollbars';
import { useAttendances } from '../../../hooks/useAttendances';
import { useSocket } from '../../../hooks/useSocket';
import { ModalWarning } from './../modalWarning';

interface User {
  id: string;
  name: string;
  phone: string;
  profile: string;
  cpf: string;
}

interface Messages {
  id: number;
  text: string;
  contactUsId: number;
  is_org: boolean;
}

interface PropsChatMessages {
  messages: Messages[];
  scrollDown: boolean;
  closed: boolean;
  user: string;
  loadingLocal: boolean;
}

export function ChatMessages({
  messages,
  scrollDown,
  closed,
  user,
  loadingLocal,
}: PropsChatMessages): JSX.Element {
  const [profile, setProfile] = useState('');
  const [text, setText] = useState('');
  const [cpf, setCpf] = useState('');
  const [number, setNumber] = useState('');
  const [loading, setLoading] = useState(false);

  const [messagess, setMessagess] = useState<Messages[]>([]);
  const [scrolldownLocal, setScrollDownLocal] = useState(scrollDown);
  const chatScroll = useRef('chatScroll');
  const { socketConnection } = useSocket();
  const [openWarning, setOpenWarning] = useState(false);
  function handleOpenWarning(): void {
    setOpenWarning(!openWarning);
  }

  const chatBoardHeight = useRef(null);
  // errors
  const [errorProfile, setErrorProfile] = useState('');
  const {
    closeAttendance,
    allAttendances,
    newMessage,
    messagesCurrent,
    setMessagesCurrentFunction,
  } = useAttendances();

  useEffect(() => {
    const setScroll = chatScroll.current;
    // setScroll.scrollTop(setScroll.getScrollHeight());
    const chatBoard = chatBoardHeight.current.clientHeight;

    // if (
    //   // @ts-expect-error
    //   parseInt(setScroll.getScrollHeight()) -
    //     // @ts-expect-error
    //     parseInt(chatScroll.current.getValues().clientHeight) <
    //   Number(chatBoard) + 272
    // ) {
    if (
      // @ts-expect-error
      parseInt(setScroll.getScrollHeight()) >
      // @ts-expect-error
      parseInt(chatScroll.current.getValues().clientHeight)
    ) {
      // @ts-expect-error
      setScroll.scrollTop(setScroll.getScrollHeight());
    }
  }, [scrollDown, scrolldownLocal, messagesCurrent]);

  async function closeAttendanceLocal(): Promise<void> {
    // const r = window.confirm(
    //   // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    //   `Deseja encerrar o atendimento "${allAttendances
    //     .filter((item) => item.id === messages[0].contactUsId)
    //     .map((item) => item.title)}"?`,
    // );
    // if (!r) return;

    try {
      setMessagesCurrentFunction([]);
    } catch (error) {
      console.log(error);
    }

    await closeAttendance(messages[0].contactUsId);
  }

  async function sendMessage(): Promise<void> {
    setLoading(true);
    const userLocal = localStorage.getItem('@expo:user') as unknown as string;
    const userFormatted = JSON.parse(userLocal) as User;
    try {
      const response = await api.post(`contact_us/add_message`, {
        text,
        is_org: true,
        id: messages[0].contactUsId,
      });
      // newMessage({
      //   text,
      //   is_org: true,
      //   contactUsId: messages[0].contactUsId,
      //   id: 9,
      // });
      // messages.push({ is_org: true, text, contactUsId: Number(userFormatted?.id), id: 90 });
      setScrollDownLocal(!scrolldownLocal);
      setText('');
      setLoading(false);
      if (response?.data?.message === 'Chamado fechado') {
        toast('Não foi possivel enviar a mensagem, pois o atendimento foi finalizado', {
          type: 'error',
        });
      }
    } catch (error) {
      toast('Não foi possivel enviar a mensagem', { type: 'error' });
      setLoading(false);
    }
  }
  socketConnection?.off('message')?.on('message', (message) => {
    // let exists;
    // let existsAux;

    // for (let index = 0; index < allAttendances?.length; index++) {
    //   if (allAttendances[index]?.id === message?.contactUsId)
    //     exists = allAttendances[index]?.messages;
    // }

    // for (let index = 0; index < exists?.length; index++) {
    //   if (exists[index]?.id === message.id) existsAux = exists[index];
    // }

    // if (existsAux === undefined) {

    newMessage(message);
    // }
  });

  return (
    <>
      {loadingLocal ? (
        <div
          className="col-sm-8"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Spinner type="border" size={'sm'} color="primary" />
        </div>
      ) : (
        <div
          className="col-sm-8"
          style={{
            display: 'flex',
            flexDirection: 'column',
            // width: '50%',
            border: '1px solid rgba(0, 0, 0, 0.05)',
            height: 50,
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              height: 90,
              // paddingLeft: 20,
              marginTop: 50,
              border: '1px solid rgba(0, 0, 0, 0.05)',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '70%',
                flexDirection: 'column',
                justifyContent: 'center',
                // backgroundColor: 'purple',
                padding: 40,
              }}
            >
              <span>
                <b>{user}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                width: '30%',
                flexDirection: 'column',
                justifyContent: 'center',
                // backgroundColor: 'green',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  // backgroundColor: 'blue',
                  borderRadius: 12,
                  justifyContent: 'end',
                  color: '#F6F6F6',
                  marginRight: 20,
                }}
              >
                <Button onClick={handleOpenWarning} color={'danger'} disabled={closed}>
                  Encerrar atendimento
                </Button>
              </div>
            </div>
          </div>
          <div
            style={{
              padding: 20,
            }}
            ref={chatBoardHeight}
          >
            <Scrollbars
              ref={chatScroll}
              className="col-sm-12"
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 20,
                height: '40vh',

                // overflowX: 'hidden',
                // overflowY: 'hidden',
              }}
              onScrollStop={() => {}}
              onScrollStart={() => {}}
              autoHide
              key={messages}
            >
              {messagesCurrent.map((item) => (
                <div
                  className="col-sm-12"
                  key={item.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: item.is_org ? 'end' : 'start',

                    marginBottom: 15,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: item.is_org ? '#A2C1E0' : '#EAE6EB',
                      color: item.is_org ? '#FFF' : '#352B2F',
                      borderRadius: 8,
                      width: 190,
                      padding: 10,
                      overflowWrap: 'break-word',
                    }}
                  >
                    <p style={{ width: 180 }}>{item.text}</p>
                  </div>
                </div>
              ))}
            </Scrollbars>
            <div
              className="col-sm-12"
              style={{
                // position: 'absolute',
                // right: 0,
                // left: 850,
                display: 'flex',
                paddingTop: 10,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '90%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Input
                  placeholder="Máx. 500 caracteres"
                  maxLength={500}
                  value={text}
                  type={'textarea'}
                  disabled={closed}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '10%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  pointerEvents: loading || text?.length <= 0 ? 'none' : 'auto',
                }}
                onClick={() => {
                  sendMessage()
                    .then(() => {})
                    .catch(() => {});
                }}
              >
                {loading ? (
                  <Spinner type="border" size={'sm'} color="success" />
                ) : (
                  <FaArrowCircleRight size={35} color="#01874A" />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <ModalWarning
        action={() => {
          closeAttendanceLocal()
            .then(() => {})
            .catch(() => {});
        }}
        title={'Deseja encerrar o atendimento?'}
        handleIsOpen={handleOpenWarning}
        isOpen={openWarning}
      />
    </>
  );
}
