import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Loading from 'react-loading';
import { Card, CardBody, CardTitle } from 'reactstrap';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TimeScale,
  PointElement,
  LineElement,
  Filler,
} from 'chart.js';
import * as faker from '@faker-js/faker';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { api } from '../../services/api';
import { CardContainer, Container } from './Card.styled';
import { FilterYear } from '../components/filterYear';
import { useEvent } from '../../hooks/useEvent';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  PointElement,
  LineElement,
  ArcElement,
  Filler,
);

export function Dashboard(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const { setCurrentYear, currentYear } = useEvent();
  const [ratingExpofeira, setRatingExpofeira] = useState([]);
  const [ratingExpofeiraMean, setRatingExpofeiraMean] = useState(0);
  const [rating, setRating] = useState([]);
  const [ratingMean, setRatingMean] = useState(0);
  const [usersRegistered, setUsersRegistered] = useState(0);
  const [ratingAll, setRatingAll] = useState(0);
  const [ticketsAll, setTicketsAll] = useState(0);
  const [contactUs, setContactUs] = useState(0);
  const [complaining, setComplaining] = useState(0);
  const [eventAll, setEventAll] = useState([]);
  const [userAll, setUserAll] = useState([]);
  useEffect(() => {
    getAllData()
      .then(() => {})
      .catch(() => {});
  }, [currentYear]);

  async function getAllData(): Promise<void> {
    try {
      const response = await api.get('dashboard', {
        params: {
          year: currentYear,
        },
      });
      setRatingExpofeira(response.data.expofeira);
      setRatingExpofeiraMean(response.data.expofeiraMean);
      setRating(response.data.rating);
      setRatingMean(response.data.ratingMean);
      setUsersRegistered(response.data.registeredUsersCount);
      setRatingAll(response.data.ratingCount);
      setTicketsAll(response.data.ticketCount);
      setComplaining(response.data.complainingCount);
      setContactUs(response.data.contactUsCount);
      setEventAll(response.data.schedulesOrdered);
      setUserAll(response.data.allUsers);
    } catch (error) {
      console.log(error);
    }
  }

  const labels = ['Atrações', 'Organização', 'Produtos', 'Segurança', 'Transporte'];
  const labelsEvent = ['Ótimo', 'Bom', 'Regular', 'Ruim', 'Péssimo'];
  const data = {
    labels,
    datasets: [
      {
        label: 'Avaliação',
        data: ratingExpofeira?.map((item) => item),
        // borderColor: 'rgb(255, 99, 132)',
        backgroundColor: '#2D9CDB',
        borderRadius: 5,
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        display: false,
        grid: {
          display: false,
        },
        max: 5,
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const dataEvent = {
    labels: labelsEvent,
    datasets: [
      {
        label: 'Avaliação',
        data: rating?.map((item) => item),
        // borderColor: 'rgb(255, 99, 132)',
        backgroundColor: '#2D9CDB',
        borderRadius: 5,
      },
    ],
  };

  const DATA_COUNT = 7;
  const NUMBER_CFG = { count: DATA_COUNT, min: 0, max: 100 };

  const datas = {
    labels: [
      // Date Objects
      new Date(),
    ],
    datasets: [
      {
        label: 'My First dataset',
        backgroundColor: 'red',
        // borderColor: Utils.CHART_COLORS.red,
        fill: false,
        data: NUMBER_CFG,
        showLine: true,
      },
    ],
  };
  // const config = {
  //   type: 'line',
  //   data,
  //   options: {
  //     plugins: {
  //       title: {
  //         text: 'Chart.js Time Scale',
  //         display: true,
  //       },
  //     },
  //     scales: {
  //       x: {
  //         type: 'time',
  //         time: {
  //           // Luxon format string
  //           tooltipFormat: 'DD T',
  //         },
  //         title: {
  //           display: true,
  //           text: 'Date',
  //         },
  //       },
  //       y: {
  //         title: {
  //           display: true,
  //           text: 'value',
  //         },
  //       },
  //     },
  //   },
  // };
  const optionsLine = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
        display: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      filler: {
        propagate: true,
      },
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
  };

  const dataLine = {
    labels: userAll?.map((item) => item.date),
    datasets: [
      {
        label: 'Usuários',
        data: userAll?.map((item) => item.total),
        fill: true,
        tension: 0.3,
        borderColor: '#FF847C',
        backgroundColor: '#FF847C',
        segment: {
          backgroundColor: '#FFE6E5',
          borderColor: '#FF847C',
        },
      },
    ],
  };

  const dataPie = {
    labels: eventAll?.map((item) => item.name),
    datasets: [
      {
        label: 'Quantidade',
        data: eventAll?.map((item) => item.total),
        backgroundColor: ['#F94144', '#8FD0D4', '#F9C74F', '#90BE6D', '#2D9CDB', '#F3722C'],
        borderColor: ['#F94144', '#8FD0D4', '#F9C74F', '#90BE6D', '#2D9CDB', '#F3722C'],
        borderWidth: 1,
      },
    ],
  };
  const optionsPie = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as const,
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
  };
  return (
    <div style={{ width: '100%', backgroundColor: '#FFF', minHeight: '90vh' }}>
      {loading ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 25 }}
        >
          <Loading width={30} color={'#005675'} type={'spin'} />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#EEF2F5',
            minHeight: '90vh',
          }}
        >
          <Container
            className="col-sm-12"
            style={{
              display: 'flex',
              // flexDirection: 'row',

              justifyContent: 'center',
            }}
          >
            <div
              className="col-sm-4"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: 10,
                marginTop: 5,
              }}
            >
              <CardContainer
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  width: '49%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 100,
                }}
              >
                <CardTitle
                  className="title"
                  title="Usuários Cadastrados"
                  style={{ color: '#828282' }}
                >
                  Usuários Cadastrados
                </CardTitle>
                {/* <CardBody> */}
                <b style={{ color: '#272859', fontSize: 34 }}>{usersRegistered}</b>
                {/* </CardBody> */}
              </CardContainer>

              <CardContainer
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  width: '49%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 100,
                }}
              >
                <CardTitle
                  className="title"
                  title="Avaliações Realizadas"
                  style={{ color: '#828282' }}
                >
                  Avaliações Realizadas
                </CardTitle>
                {/* <CardBody> */}
                <b style={{ color: '#272859', fontSize: 34 }}>{ratingAll}</b>
                {/* </CardBody> */}
              </CardContainer>
            </div>
            <div
              className="col-sm-7"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: 10,
                marginBottom: 5,
                marginTop: 5,
              }}
            >
              <CardContainer
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 100,
                  width: '33%',
                }}
              >
                <CardTitle
                  className="title"
                  title="Cupons Cadastrados"
                  style={{ color: '#828282' }}
                >
                  Cupons Cadastrados
                </CardTitle>
                {/* <CardBody> */}
                <b style={{ color: '#272859', fontSize: 34 }}>{ticketsAll}</b>
                {/* </CardBody> */}
              </CardContainer>

              <CardContainer
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 100,
                  width: '33%',
                }}
              >
                <CardTitle className="title" style={{ color: '#828282' }}>
                  Fale Conosco
                </CardTitle>
                {/* <CardBody> */}
                <b style={{ color: '#272859', fontSize: 34 }}>{contactUs}</b>
                {/* </CardBody> */}
              </CardContainer>

              <CardContainer
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 100,
                  width: '33%',
                }}
              >
                <CardTitle className="title" style={{ color: '#828282' }}>
                  Reclamações
                </CardTitle>
                {/* <CardBody> */}
                <b style={{ color: '#272859', fontSize: 34 }}>{complaining}</b>
                {/* </CardBody> */}
              </CardContainer>
            </div>
          </Container>
          <Container
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className="col-sm-4"
              style={{
                display: 'flex',
                flexDirection: 'column',

                borderRadius: 8,
                marginLeft: 10,
              }}
            >
              <div style={{ backgroundColor: '#FFF', borderRadius: 8, height: 340 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '70%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <span>
                      <b style={{ fontSize: 19 }}>Avaliação da Expofeira</b>
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '30%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 25,
                    }}
                  >
                    <span style={{ color: '#272859', fontSize: 25 }}>
                      {isNaN(ratingExpofeiraMean) ? 0 : ratingExpofeiraMean} / 5
                    </span>
                  </div>
                </div>

                <div style={{ paddingLeft: 25, paddingRight: 25, height: '28vh' }}>
                  <Bar data={data} options={options} />
                </div>
              </div>
              <div style={{ backgroundColor: '#FFF', marginTop: 10, borderRadius: 8, height: 340 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '70%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <span>
                      <b style={{ fontSize: 19 }}>Avaliação dos Eventos</b>
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '30%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 25,
                    }}
                  >
                    <span style={{ color: '#272859', fontSize: 25 }}>
                      {isNaN(ratingMean) ? 0 : ratingMean} / 5
                    </span>
                  </div>
                </div>
                <div
                  className="col-sm-12"
                  style={{
                    paddingLeft: 25,
                    paddingRight: 25,
                    position: 'relative',
                    margin: 'auto',
                    height: '28vh',
                    // width: '80vw',
                  }}
                >
                  <Bar data={dataEvent} options={options} />
                </div>
              </div>
            </div>
            <div
              className="col-sm-7"
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 5,
                borderRadius: 8,
                marginLeft: 10,
              }}
            >
              <div style={{ backgroundColor: '#FFF', borderRadius: 8, height: 340 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '70%',
                      justifyContent: 'start',
                      alignItems: 'end',
                    }}
                  >
                    <span>
                      <b style={{ fontSize: 19, paddingLeft: 118 }}>Cadastro de Usuários</b>
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '30%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 10,
                    }}
                  >
                    <span style={{ color: '#4F4F4F', fontSize: 16 }}>por dia</span>
                  </div>
                </div>

                <div
                  className="col-sm-11"
                  style={{
                    display: 'flex',

                    // position: 'relative',
                    paddingLeft: 40,
                    height: '28vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Line
                    style={{ justifyContent: 'center' }}
                    data={dataLine}
                    options={optionsLine}
                  />
                </div>
              </div>
              <div style={{ backgroundColor: '#FFF', marginTop: 10, borderRadius: 8 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '70%',
                      justifyContent: 'start',
                      alignItems: 'end',
                    }}
                  >
                    <span>
                      <b style={{ fontSize: 19, paddingLeft: 100 }}>Favoritados</b>
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '30%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 10,
                    }}
                  >
                    <span style={{ color: '#4F4F4F', fontSize: 16 }}>por evento</span>
                  </div>
                </div>
                <div
                  className="col-sm-11"
                  style={{
                    display: 'flex',

                    // position: 'relative',
                    paddingLeft: 40,
                    paddingBottom: 5,
                    height: '30vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Pie data={dataPie} options={optionsPie} />
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
}
