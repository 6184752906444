import styled from "styled-components";
import {Table,Button} from 'reactstrap'

export const Row = styled(Table)`
tbody{
  & th {
    height: 190px;
    width: 90px;
  }
}


`;

export const Icon = styled(Button)`
    background-color:#005FAD;
    align-items: center;
    width: 70%;
    height: 70%; 
    span {
      display:flex;
      justify-content: space-evenly;
      margin-right: .5rem;
      align-items: center;
      width:100%;
    }



`;