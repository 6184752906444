import {useContext} from 'react'
import {EventContext,type EventContextData} from './../context/event'

export function useEvent(): EventContextData {
  const context = useContext(EventContext);

  if (context === null || context === undefined) {
    throw new Error('useEvent must be used within an eventProvider');
  }

  return context;
}