import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { HiOutlineCalendar } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { SideBarContainer } from './SideBar.styled';
import { useSidebarList } from './sidebarList';
import { useSideBarSelected } from './../../hooks/useSideBarSelected';

import MobileSideBar from './sideBarMobile';

function SideBar(): JSX.Element {
  const permission = useSidebarList();
  const { handleChangeSelected, sidebarSelectedItem } = useSideBarSelected();
  // const [selected, setSelected] = React.useState<any>({
  //   icon: <HiOutlineCalendar />,
  //   title: 'Eventos',
  //   to: '/schedule',
  // });
  const [sidebar, setSidebar] = useState(false);

  const showSiderbar = (): void => {
    setSidebar(!sidebar);
  };

  return (
    <>
      <SideBarContainer>
        <div className="menu-hamburger">
          <FaBars size={20} onClick={showSiderbar} />
        </div>
        <img
          src="assets/IMAGE.png"
          width={170}
          // style={{
          //   display: 'block',
          //   marginLeft: 'auto',
          //   marginRight: 'auto',
          //   marginTop: 15,
          // }}
          className={'imgg'}
          // className="mx-auto d-none d-md-block"
          height={'auto'}
        />
        <div className="menu-section">
          {sidebar && <MobileSideBar active={setSidebar} />}
          <div style={{ marginTop: 20 }} />
          {permission.functions.map((item, i) => (
            <Link key={Number(i)} style={{ textDecoration: 'none' }} to={item.to}>
              <button
                className="list-menu"
                onClick={() => {
                  // @ts-expect-error
                  handleChangeSelected(item);
                }}
                // style={{
                //   background: selected?.title === item.title ? 'rgba(0,62,93, 0.3)' : '#FFF',
                // }}
              >
                {sidebarSelectedItem?.title === item.title && (
                  <div
                    style={{
                      position: 'relative',
                      right: 0,
                      background: 'rgba(0,62,93)',
                      width: 10,
                      height: '100%',
                    }}
                  />
                )}
                <div
                  style={{
                    paddingLeft: sidebarSelectedItem?.title === item.title ? 10 : 20,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {item.icon} <h5 className="text"> {item.title}</h5>
                </div>
              </button>
            </Link>
          ))}
          <div className="title-section" />
        </div>
      </SideBarContainer>
    </>
  );
}

export default SideBar;
