import React, { createContext, useCallback, useEffect, useState, type ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { connect, type Socket } from 'socket.io-client';
import { useSocket } from '../hooks/useSocket';
import { api } from '../services/api';

interface Messages {
  id: number;
  text: string;
  contactUsId: number;
  is_org: boolean;
  read: boolean;
}

export interface ListAttendance {
  id: number;
  title: string;
  userId: number;
  closed: boolean;
  category: string;
  messages: Messages[];
  user: { name: string };
}

export interface AllAttendancesContextData {
  allAttendances: ListAttendance[];
  newMessage: (message: Messages) => void;
  getAllAttendances: (shouldIdLoading: boolean) => Promise<void>;
  closeAttendance: (id: number) => Promise<void>;
  loading: boolean;
  messagesCurrent: Messages[];
  setMessagesCurrentFunction: (messages: Messages[]) => void;
  setMessagesCurrentRenewFunction: (messages: Messages[]) => void;
  handleNewUnreadMessage: (id: number, message: Messages) => void;
  aux: boolean;
  handleSetAuxFalse: () => void;
  // handleAllNewMessagesSideBar: () => void;
}

interface AllAttendancesContextProviderProps {
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const AllAttendancesContext = createContext({} as AllAttendancesContextData);

export function AllAttendancesContextProvider(
  props: AllAttendancesContextProviderProps,
): JSX.Element {
  const history = useHistory();
  const [allAttendances, setAllAttendances] = useState<ListAttendance[]>([]);
  const [loading, setLoading] = useState(true);
  const [messagesCurrent, setMessagesCurrent] = useState<Messages[]>([]);
  const { socketConnection } = useSocket();
  const [aux, setAux] = useState(false);
  const [allNewMessages, setAllNewMessages] = useState(0);

  useEffect(() => {
    getAllAttendances(true)
      .then(() => {})
      .catch(() => {});
  }, []);

  function handleSetAuxFalse(): void {
    setAux(false);
  }

  function setMessagesCurrentRenewFunction(messages: Messages[]): void {
    setMessagesCurrent(messages);
  }
  function setMessagesCurrentFunction(messages: Messages[]): void {
    setMessagesCurrent([...messages]);
  }

  async function setMessagesRead(contactUsId: number): Promise<void> {
    // setLoadingLocal(true);
    try {
      const response = await api.get(`contact_us/read/${contactUsId}`);
      // setLoadingLocal(false);
    } catch (error) {}
  }

  function newMessage(message: Messages): void {
    for (let index = 0; index < allAttendances.length; index++) {
      if (Number(message.contactUsId) === Number(allAttendances[index].id)) {
        allAttendances[index].messages.push(message);
      }
    }
    if (messagesCurrent !== undefined) {
      if (message.contactUsId === messagesCurrent[0].contactUsId) {
        messagesCurrent.push(message);
        setMessagesRead(message.contactUsId)
          .then(() => {})
          .catch(() => {});
        // setMessagesCurrent(messagesCurrent);
        setMessagesCurrentFunction(messagesCurrent);
      } else {
        handleNewUnreadMessage(message.contactUsId, message);
      }
    } else {
      handleNewUnreadMessage(message.contactUsId, message);
    }
  }

  function handleNewUnreadMessage(idAttendance: number, messages: Messages): void {
    setAux(true);

    // for (let index = 0; index < allAttendances.length; index++) {
    //   if (allAttendances[index].id === idAttendance) {
    //     try {
    //       if (allAttendances[index]?.unreadMessages?.length > 0) {
    //         allAttendances[index]?.unreadMessages?.push(messages);
    //       } else {
    //         const aux = [messages];
    //         allAttendances[index].unreadMessages = aux;
    //       }
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }
    // }
    // let auxAll = allNewMessages;
    // setAllNewMessages((auxAll += 1));
  }

  async function getAllAttendances(shouldIdLoading: boolean): Promise<void> {
    if (shouldIdLoading) {
      setLoading(true);
      try {
        const response = await api.get(`contact_us/`);

        setAllAttendances(response.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      try {
        const response = await api.get(`contact_us/`);
        setAllAttendances(response.data);
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function closeAttendance(id: number): Promise<void> {
    setLoading(true);
    try {
      const attendance = await api.post(`contact_us/close/${id}`);
      await getAllAttendances(true);
      toast('Atendimento finalizado com sucesso!', { type: 'success' });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast('Não foi possível finalizar o atendimento', { type: 'error' });
    }
  }

  return (
    <AllAttendancesContext.Provider
      value={{
        allAttendances,
        newMessage,
        getAllAttendances,
        closeAttendance,
        loading,
        setMessagesCurrentFunction,
        messagesCurrent,
        setMessagesCurrentRenewFunction,
        handleNewUnreadMessage,
        aux,
        handleSetAuxFalse,
        // handleAllNewMessagesSideBar,
      }}
    >
      <>
        <ToastContainer />
        {props.children}
      </>
    </AllAttendancesContext.Provider>
  );
}
