import React, { useEffect, useState, useMemo, useCallback } from 'react';
// import styled from 'styled-components';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Badge,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardDeck,
  CardImg,
  CardText,
} from 'reactstrap';
import 'react-calendar/dist/Calendar.css';
import { Calendar } from 'react-calendar';
import {
  FaArrowCircleRight,
  FaArrowRight,
  FaComment,
  FaEdit,
  FaEye,
  FaPlus,
  FaSearch,
  FaTrash,
} from 'react-icons/fa';
import { BiCommentCheck, BiCommentError, BiCommentX } from 'react-icons/bi';
import { CalendarLocal } from '../components/CalendarLocal';
import { ModalAddEvent } from '../components/modalAddEvent/index';
import { useEvent } from './../../hooks/useEvent';
import { useSocket } from './../../hooks/useSocket';
import Loading from 'react-loading';
import SearchBar from '../components/searchBar';
import { apiAp } from './../../services/apiAmapá';
import { api } from './../../services/api';
import axios from 'axios';
import { ModalNews } from '../components/modalNews';
import { toast } from 'react-toastify';
import { Buffer } from 'buffer';
import { ModalEditNews } from '../components/modalEditNews';
import { ChatList } from '../components/chatList';
import { ChatMessages } from '../components/chatMessages';
import { useAttendances } from './../../hooks/useAttendances';

interface Messages {
  id: number;
  text: string;
  contactUsId: number;
  is_org: boolean;
}

interface ListAttendance {
  id: number;
  title: string;
  userId: number;
  closed: boolean;
  category: string;
  messages: Messages[];
}

export function Chat(): JSX.Element {
  const [inputAdded, setInputAdded] = useState('');
  const [inputNotAdded, setInputNotAdded] = useState('');

  const { socketConnection } = useSocket();
  const {
    getAllAttendances,
    allAttendances,
    newMessage,
    loading,
    setMessagesCurrentFunction,
    handleNewUnreadMessage,
    aux,
    handleSetAuxFalse,
  } = useAttendances();

  // const [loading, setLoading] = useState(false);
  const [loadingLocal, setLoadingLocal] = useState(false);

  // const [complimenting, setComplimenting] = useState(false);
  // const [suggesting, setSuggesting] = useState(false);
  // const [complaining, setComplaining] = useState(false);
  const [category, setCategory] = useState('Elogio');

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [septemberDays, setSeptemberDays] = useState<Date[]>([]);
  const [isOpenModalNews, setIsOpenModalNews] = useState(false);
  const [isOpenModalNewsLocal, setIsOpenModalNewsLocal] = useState(false);
  const [allAttendancesLocal, setAllAttendacesLocal] = useState<ListAttendance[]>([]);
  const [allAttendancesLocalDefault, setAllAttendacesLocalDefault] = useState<ListAttendance[]>([]);

  function handleIsOpenModalNewsLocal(): void {
    setIsOpenModalNewsLocal(!isOpenModalNewsLocal);
  }
  function handleIsOpenModalNews(): void {
    setIsOpenModalNews(!isOpenModalNews);
  }

  useEffect(() => {
    getAllAttendancesLocal(true);
  }, [category]);
  useEffect(() => {
    if (aux) {
      getAllAttendancesLocal(false);
    }
  }, [aux]);

  function getAllAttendancesLocal(shoudItLoading: boolean): void {
    getAllAttendances(shoudItLoading)
      .then(() => {
        try {
          if (!aux) {
            setMessagesCurrentFunction([]);
          }
          setAllAttendacesLocal(
            allAttendances.filter((item) => item.category === category).map((item) => item),
          );
          setAllAttendacesLocalDefault(
            allAttendances.filter((item) => item.category === category).map((item) => item),
          );

          // console.log(response.data, category);
          // setAllAttendaces(response.data);
          // setAllAttendacesDefault(response.data);
          // setNewsList(data);
        } catch (error) {
          console.log(error, 'teste');
        }
      })
      .catch((error) => {
        console.log(error);
      });
    handleSetAuxFalse();
  }

  // socketConnection.on('message', (message) => {
  //   let exists;
  //   let existsAux;

  //   for (let index = 0; index < allAttendances?.length; index++) {
  //     if (allAttendances[index]?.id === message?.contactUsId)
  //       exists = allAttendances[index]?.messages;
  //   }

  //   for (let index = 0; index < exists?.length; index++) {
  //     if (exists[index]?.id === message.id) existsAux = exists[index];
  //   }

  //   if (existsAux === undefined) {
  //     newMessage(message);
  //   }
  // });

  function handleCards(value: number): void {
    switch (value) {
      case 1:
        setCategory('Elogio');
        break;
      case 2:
        setCategory('Sugestão');
        break;
      case 3:
        setCategory('Reclamação');
        break;

      default:
        break;
    }
  }
  return (
    <div style={{ width: '100%', backgroundColor: '#FFF' }}>
      {loading ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 25 }}
        >
          <Loading width={30} color={'#005675'} type={'spin'} />
        </div>
      ) : (
        <>
          <div style={{ backgroundColor: '#F2F2F6' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                paddingTop: 15,
                paddingBottom: 10,
              }}
            >
              <Card className="col-sm-3">
                <CardHeader style={{ backgroundColor: '#28A745' }}>
                  <BiCommentCheck color={'#fff'} size={30} />
                  <span style={{ color: '#fff', fontSize: 18 }}>
                    <b> Elogio</b>
                  </span>
                </CardHeader>
                <CardBody>
                  <CardText> Acompanhe o feedback dos usuários.</CardText>
                  <Button
                    onClick={() => {
                      handleCards(1);
                    }}
                    color="secondary"
                  >
                    Acompanhar
                  </Button>
                </CardBody>
              </Card>
              <Card className="col-sm-3">
                <CardHeader style={{ backgroundColor: '#FFC107' }}>
                  <BiCommentError color={'#fff'} size={30} />
                  <span style={{ color: '#fff', fontSize: 18 }}>
                    <b> Sugestão</b>
                  </span>
                </CardHeader>
                <CardBody>
                  <CardText> Acompanhe o feedback dos usuários.</CardText>
                  <Button
                    onClick={() => {
                      handleCards(2);
                    }}
                    color="secondary"
                  >
                    Acompanhar
                  </Button>
                </CardBody>
              </Card>
              <Card className="col-sm-3">
                <CardHeader style={{ backgroundColor: '#DC3545' }}>
                  <BiCommentX color={'#fff'} size={30} />
                  <span style={{ color: '#fff', fontSize: 18 }}>
                    <b> Reclamação</b>
                  </span>
                </CardHeader>
                <CardBody>
                  <CardText> Acompanhe o feedback dos usuários.</CardText>
                  <Button
                    onClick={() => {
                      handleCards(3);
                    }}
                    color="secondary"
                  >
                    Acompanhar
                  </Button>
                </CardBody>
              </Card>
            </div>
          </div>
          {allAttendances.filter((item) => item.category === category).map((item) => item)?.length >
          0 ? (
            <ChatList
              category={category}
              listAttendances={allAttendances
                .filter((item) => item.category === category)
                .map((item) => item)}
            />
          ) : (
            <div
              className="col-sm-12"
              style={{
                display: 'flex',
                flexDirection: 'row',
                // maxWidth: '500',
                backgroundColor: '#F6F8FC',
                height: '70vh',
                border: '1px solid rgba(0, 0, 0, 0.05)',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span>
                Não foi encontrado nenhum atendimento para a categoria <b>{category}</b>.
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
}

// const WrapperContainer = styled.div`
//   width: 100%;

//   .content {
//     width: 100%;

//     & div {
//       display: flex;
//     }

//     select:focus {
//       outline: none;
//     }

//     textarea:focus {
//       outline: none;
//     }

//     & input {
//       width: 100%;
//     }

//     & input:focus {
//       outline: none !important;
//     }

//     .inputfile {
//       width: 0.1px;
//       height: 0.1px;
//       opacity: 0;
//       overflow: hidden;
//       position: absolute;
//       z-index: -1;
//     }
//     .inputfile:focus + label {
//       outline: 1px dotted #000;
//       outline: -webkit-focus-ring-color auto 5px;
//     }
//     .inputfile + label * {
//       pointer-events: none;
//     }
//   }
// `;
