import React from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { Schedule } from './../authenticatedScreens/schedule/schedule';
import { News } from './../authenticatedScreens/news/news';
import { PageError } from './../authenticatedScreens/components/pageErrorNotProfileDefined';
import SideBar from './../components/sidebar/Sidebar';
import { WarningNoProfileDefined } from './../authenticatedScreens/warningNoProfileDefined/index';
import { useAuth } from './../hooks/useAuth';

const NoProfileDefinedRoutes = ({ path: string, ...rest }): JSX.Element => {
  const { user } = useAuth();
  return (
    <Router>
      {user?.profile === 'Cidadão/Administrador ' ||
        (user?.profile === 'Cidadão/Organizador ' && <SideBar />)}

      <Switch>
        <Route exact path="/">
          <Redirect to="/noProfileDefined" />
        </Route>
        <Route exact path="/noProfileDefined" component={WarningNoProfileDefined} />
        <Route path="*" component={PageError} />
      </Switch>
    </Router>
  );
};

export default NoProfileDefinedRoutes;
