import styled from 'styled-components';
import { Table, Button } from 'reactstrap';

export const Row = styled(Table)`
  tbody {
    & td {
      height: 190px;
      width: 90px;
    }
  }
  .calendar-daytoday {
    color: red;
  }
`;
