import React, { useEffect, useState } from 'react';
import { FaAndroid, FaApple, FaAppStore, FaGooglePay, FaGooglePlay } from 'react-icons/fa';
import { AiFillAndroid } from 'react-icons/ai';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import { FormGroup, Form, Input, Label, Button, Spinner } from 'reactstrap';
import { api } from '../../services/api';

import { useAuth } from './../../hooks/useAuth';

export const App = (): JSX.Element => {
  return (
    <div
      className="container"
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '100vh',
        // width: '100%',
      }}
    >
      <div
        className="mx-auto d-none d-md-block"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '50%',
        }}
      >
        <img src="/assets/IMAGE.png" alt="icone-amapa" width={'100%'} height={'auto'} />
      </div>
      <div
        className="col-sm"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          className="col-sm-7"
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid rgba(0, 0, 0, 0.05)',
            borderRadius: 8,
            // display: 'flex',
            // flexDirection: 'column',
            // justifyContent: 'center',
            // alignItems: 'center',
            // height: '100%',
            // width: '100%',
            // margin: 'auto',
            // position: 'absolute',
          }}
        >
          <div
            style={{
              textAlign: 'right',
              justifyContent: 'right',
              marginBottom: 50,
            }}
          >
            {/* <p style={{ fontSize: '9' }}>
              Primeiro acesso?{' '}
              <a style={{ textDecoration: 'none' }} href="/register">
                Crie uma conta
              </a>
            </p> */}
          </div>
          <div style={{ marginBottom: 25, textAlign: 'center', justifyContent: 'center' }}>
            <h5>Baixe o Aplicativo Expofeira:</h5>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src="/assets/icon.jpeg" width={150} height={150} />
            </div>
            <br />
            <span>Disponível nas plataformas Android e Apple</span>
            <div>
              <span style={{ fontSize: 15 }}>(Clique na sua plataforma abaixo)</span>
            </div>
          </div>

          <Form>
            <FormGroup>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    width: '50%',
                    justifyContent: 'center',
                  }}
                >
                  <AiFillAndroid
                    onClick={() =>
                      (window.location.href =
                        'https://play.google.com/store/apps/details?id=br.com.msbtec.expofeira&pcampaignid=web_share')
                    }
                    style={{ cursor: 'pointer' }}
                    size={70}
                    color={'#78C156'}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '50%',
                    justifyContent: 'center',
                  }}
                >
                  <FaApple
                    onClick={() =>
                      (window.location.href =
                        'https://apps.apple.com/br/app/expofeira/id6466657622')
                    }
                    style={{ cursor: 'pointer' }}
                    size={70}
                    color={'#ABABAB'}
                  />
                </div>
              </div>
            </FormGroup>
          </Form>
        </div>
      </div>
    </div>
  );
};
