import styled from "styled-components";

export const SideBarContainer = styled.div`
  min-height: 100%;
  max-height: 100%;
  background: #FFF;
  border-right:1px solid #F6F6F6;
  
  // padding-top: 20px;
  // margin-right: 30px;
  
  .menu-hamburger{
    display: none;
  }
  .text{
    padding-left: 15px;
  }
  .menu-section{
    width: 240px;
    left: 0;
    overflow-x: hidden;
  }

  .tab-menu{
    display: none;
  }

  .title-section {
    display: flex;
    align-items: center;
    color: #003E5D;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: 30px;
    margin-right: 10px;
    // padding: 5px 5px;
    justify-content: space-around;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
  }

  .title-section:hover {
    backgroud:red;
  }

  .list-menu {
    display: flex;
    align-items: center;
    background: #fff;
    border: none;
    text-decoration: none;
    // border-radius: 15px;
    width: 100%;
    height: 60px;
    // margin: 10px;
    // padding-left: 20px;

    img {
      width: 20px;
      /* height: 38px; */
      margin-right: 10px;
      margin-left: 10px;
    }

    h5 {
      font-size: 16px;
      margin-top: 10px;
      font-weight: bold;
    }

  }

  .list-menu:hover{
    // background-color: #ccc;
    // border-radius: 4px;
    // transition: all 0.2s ease-out;
    //cursor: pointer;
    background-color: #f7f8f9;
  }
  .imgg{
    display:block;
    margin-left:auto;
    margin-right:auto;
    margin-top:15px;
  }

  


  /* .list-menu:active {
    vertical-align: top;
    padding-top: 8px;
    border: none;
    text-decoration: none;
  } */

  .list-menu:focus { outline: none; }

  .title-section-2 {
    display:flex;
    align-items:center;
    color:#003E5D;
    margin-left: 30px;
    width: 100%;
    margin-top:30px;
    margin-bottom: 30px;
    font-size:20px;
    font-weight:bold;
  }

  @media (max-width: 1024px) {
    widht:20px;
    .text {
      display: none;
    }

    .title-section {
      display: none;
    }

    .menu-section {
      width: 40px;
    }

    .menu-hamburger {
      display: block;
      margin-top: 22px;
      margin-left: 10px;
      cursor: pointer;
    }

    .padding-left {
      padding-left: 0px;
    }

    .imgg {
      display: none;
    }

    .list-menu {
      display: none;
      width: 100%;
      height: 50px;
      img {
        display: none;
      
    }
    }
  }

`;