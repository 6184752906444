import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
  useEffect,
  type ReactNode,
} from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { api } from '../services/api';

interface User {
  id: number;
  name: string;
  cpf: string;
  phone: string;
  email: string;
  ratingNumber: string;
  ratingText: string;
  accept_use_terms: boolean;
  profile: string;
}

interface AuthState {
  auth: string;
  user: User;
}

interface SignInCredentials {
  cpf: string;
  password: string;
}

export interface AuthContextData {
  user: User | null;
  loading: boolean;
  signIn: (credentias: SignInCredentials) => Promise<void>;
  signOut: () => void;
  signed: boolean;
  updateMyProfile: (profile: string) => void;
}

interface AuthContextProviderProps {
  children: ReactNode;
}

interface data {
  cpf: string;
  password: string;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const AuthContext = createContext({} as AuthContextData);

export function AuthContextProvider(props: AuthContextProviderProps): JSX.Element {
  const history = useHistory();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<AuthState>(() => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return {} as AuthState;
  });

  // const signed = useMemo(
  //   () => data?.auth?.length > 0 && Object.keys(data?.user)?.length === 0,
  //   [data],
  // );

  useEffect(() => {
    const token = localStorage.getItem('@expo:token');
    const userLocal = localStorage.getItem('@expo:user') as unknown as string;
    const userFormatted = JSON.parse(userLocal) as User;

    if (token !== null && userLocal !== null) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      api.defaults.headers.authorization = `Bearer ${token}`;
      setUser({
        id: userFormatted.id,
        name: userFormatted.name,
        cpf: userFormatted.cpf,
        phone: userFormatted.phone,
        accept_use_terms: userFormatted.accept_use_terms,
        email: userFormatted.email,
        ratingNumber: userFormatted.ratingNumber,
        ratingText: userFormatted.ratingText,
        profile: userFormatted.profile,
      });
      setLoading(false);
    }
    setLoading(false);
  }, []);

  const signIn = useCallback(async (data: data) => {
    try {
      setLoading(true);
      const response = await api.post(`/auth/login`, {
        cpf: data.cpf,
        password: data.password,
      });

      const { auth, user } = response.data as unknown as { auth: string; user: User };

      localStorage.setItem('@expo:token', auth);
      localStorage.setItem('@expo:user', JSON.stringify(user));
      api.defaults.headers.authorization = `Bearer ${auth}`;
      setData({ auth, user });
      localStorage.removeItem('inputValueCpf');
      localStorage.removeItem('inputValuePassword');
      window.location.href = '/dashboard';
    } catch (error) {
      console.log(error);
      setLoading(false);

      localStorage.removeItem('@expo:token');
      localStorage.removeItem('@expo:user');

      const message = 'Ocorreu um erro ao fazer login, verifique suas credenciais!';

      toast(message, {
        type: 'error',
      });
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@expo:token');
    localStorage.removeItem('@expo:user');
    setUser(null);

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    setData({} as AuthState);
  }, []);

  const updateMyProfile = useCallback((profile: string) => {
    const token = localStorage.getItem('@expo:token');
    const userLocal = localStorage.getItem('@expo:user') as unknown as string;
    const userFormatted = JSON.parse(userLocal) as User;

    userFormatted.profile = profile;
    localStorage.setItem('@expo:user', JSON.stringify(userFormatted));
  }, []);
  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        signed: Boolean(user),
        signIn,
        signOut,
        updateMyProfile,
      }}
    >
      <>
        <ToastContainer />
        {props.children}
      </>
    </AuthContext.Provider>
  );
}
