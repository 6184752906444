import { useContext } from 'react';
import { SocketContext, type SocketContextData } from './../context/socket';

export function useSocket(): SocketContextData {
  const context = useContext(SocketContext);

  if (context === null || context === undefined) {
    throw new Error('useSocket must be used within an SocketProvider');
  }

  return context;
}
