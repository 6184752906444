import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label,
  Spinner,
} from 'reactstrap';
import { api } from '../../../services/api';
import { DatePicker } from 'reactstrap-date-picker';
import { toast } from 'react-toastify';
import { useAuth } from './../../../hooks/useAuth';
import LocationPicker from 'react-leaflet-location-picker';
import moment from 'moment';

interface Company {
  id: string;
  name: string;
  cnpj: string;
  email: string;
  phone: string;
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  status: string;
}

interface PropsEvent {
  isOpen: boolean;
  handleIsOpen: () => void;
  company: Company;
  getAllUsers: (page: number) => Promise<void>;
}

export function ModalEditCompany({
  isOpen,
  handleIsOpen,
  company,
  getAllUsers,
}: PropsEvent): JSX.Element {
  const [status, setStatus] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [phone, setPhone] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');

  const [loading, setLoading] = useState(false);
  // errors
  const [errorStatus, setErrorStatus] = useState('');

  useEffect(() => {
    setCnpj(company.cnpj);
    setName(company.name);
    setNumber(company.number);
    setPhone(company.phone);
    setName(company.name);
    setNeighborhood(company.neighborhood);
    setCity(company.city);
    setEmail(company.email);
    setStatus(company.status);
    setState(company.state);
  }, [company, isOpen]);

  async function updateCompanyStatus(): Promise<void> {
    if (status.length < 0 || status === 'default') {
      setErrorStatus('Campo obrigatório.');
    } else {
      setErrorStatus('');
    }

    if (status.length > 1 && status !== 'default') {
      setLoading(true);

      try {
        await api.patch(`company/${company?.id}`, {
          status,
        });
        close();
        toast('Empresa atualizada com sucesso!', { type: 'success' });
        await getAllUsers(1);

        setLoading(false);
      } catch (error) {
        toast('Não foi possivel atualizar a empresa', { type: 'error' });
        setLoading(false);
      }
    }
  }

  function close(): void {
    setErrorStatus('');
    setCnpj('');
    setName('');
    setStatus('');
    handleIsOpen();
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={close} size={'lg'}>
        <ModalHeader toggle={close}>Editar empresa</ModalHeader>
        <ModalBody>
          <Form>
            <div className="row">
              <div className="col">
                <FormGroup>
                  <Label>Nome</Label>
                  <Input value={name} disabled />
                </FormGroup>
              </div>
              <div className="col">
                <FormGroup>
                  <Label>Email</Label>
                  <Input disabled value={email} />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <FormGroup>
                  <Label for="exampleEmail">CNPJ</Label>
                  <Input value={cnpj} disabled />
                </FormGroup>
              </div>
              <div className="col">
                <FormGroup>
                  <Label for="exampleEmail">Número para contato</Label>
                  <Input value={phone} disabled />
                </FormGroup>
              </div>
            </div>

            <div className="row">
              <div className="col-9">
                <FormGroup>
                  <Label for="exampleEmail">Endereço</Label>
                  <Input value={neighborhood} disabled />
                </FormGroup>
              </div>
              <div className="col">
                <FormGroup>
                  <Label for="exampleEmail">Número</Label>
                  <Input value={number} disabled />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <FormGroup>
                  <Label for="exampleEmail">Cidade</Label>
                  <Input value={city} disabled />
                </FormGroup>
              </div>
              <div className="col">
                <FormGroup>
                  <Label for="exampleEmail">Estado</Label>
                  <Input value={state} disabled />
                </FormGroup>
              </div>
            </div>

            <FormGroup>
              <Label
                style={{
                  color: errorStatus?.length > 1 ? '#E44B39' : '#2C2C2D',
                }}
                for="exampleEmail"
              >
                Status
              </Label>
              <Input
                style={{ borderColor: errorStatus?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                type="select"
                name="select"
                id="exampleSelect"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <option key={'default'} value={'default'}>
                  Selecione o status
                </option>
                <option key={'pendent'} value={'Pendente'}>
                  Pendente
                </option>
                <option key={'active'} value={'Aprovado'}>
                  Aprovado
                </option>
                <option key={'active'} value={'Negado'}>
                  Negado
                </option>
              </Input>

              <text
                style={{
                  fontSize: 14,
                  color: '#E44B39',
                  marginBottom: 5,
                }}
              >
                {errorStatus}
              </text>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async () => {
              await updateCompanyStatus();
            }}
            style={{ minWidth: 120 }}
            disabled={loading}
          >
            {loading ? <Spinner type="border" size={'sm'} color="light" /> : 'Atualizar status'}
          </Button>{' '}
          <Button color="secondary" onClick={close}>
            Cancelar
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}
