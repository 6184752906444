import React, { useState } from 'react';
import { FaSignOutAlt, FaUserCircle } from 'react-icons/fa';

import { GoBell } from 'react-icons/go';

import { useHistory } from 'react-router-dom';
import { NavBarContainer } from './NavBar.styled';
import { useAuth } from './../../hooks/useAuth';
// import { SelecionarPerfilModal } from '../SelecionarPerfilModal';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { FilterYear } from '../../authenticatedScreens/components/filterYear';
import { useEvent } from '../../hooks/useEvent';

const NavBar = (): JSX.Element => {
  const [isClicked, setClick] = useState(false);
  const { loading, getAll, setCurrentYear, currentYear } = useEvent();
  const { user, signed, signOut } = useAuth();

  const [isOpenOptions, setIsOpenOptions] = useState(false);

  // const handleClick = () => {
  //   setClick(!isClicked);
  // };

  const history = useHistory();

  return (
    <NavBarContainer>
      <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
        <span>Bem vindo, {user.name}</span>
      </div>
      {/* <div
        onClick={() => {
          // history.push('dashboard');
          window.location.href = '/dashboard';
        }}
        style={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: 'red',
        }}
      ></div> */}

      <div style={{ display: signed ? '' : 'none' }}>
        {signed && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FilterYear currentYear={currentYear} setCurrentYear={setCurrentYear} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
                fontSize: 16,
                backgroundColor: '#222D3E',
                borderRadius: 8,
                width: 90,
                alignItems: 'center',
                height: 30,
                marginLeft: 15,
              }}
              onClick={signOut}
            >
              <div style={{ display: 'flex', marginRight: 5 }}>
                <FaSignOutAlt />
              </div>
              <div>
                <span className="logout" onClick={signOut}>
                  Sair
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </NavBarContainer>
  );
};

export default NavBar;
