import React, { useEffect, useState, useMemo, useRef } from 'react';
// import styled from 'styled-components';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import 'react-calendar/dist/Calendar.css';
import { Calendar } from 'react-calendar';
import { FaPlus } from 'react-icons/fa';
import Loading from 'react-loading';
import { ImageOverlay, TileLayer, useMap } from 'react-leaflet';
import LIR from 'leaflet-imageoverlay-rotated';
import L from 'leaflet';

interface type {
  handleChange?: (latLng) => void;
  lat?: string;
  lng?: string;
}

export function Map({ handleChange, lat, lng }: type): JSX.Element {
  const mapa = useMap();
  let marker;
  let overlay;
  const point1 = L.latLng(-0.041353, -51.111973);
  const point2 = L.latLng(-0.039017, -51.100686);
  const point3 = L.latLng(-0.044234, -51.111501);
  useEffect(() => {
    console.log(LIR);

    // insert
    // eslint-disable-next-line new-cap
    // const customMarker = new L.icon({
    //   iconUrl: 'https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png',
    //   iconSize: [25, 41],
    //   iconAnchor: [12, 41],
    // });
    // const marker1 = L.marker(L.latLng(-0.042703, -51.107032), {
    //   icon: customMarker,
    //   draggable: true,
    // }).addTo(mapa);
    // const marker2 = L.marker(point2, { icon: customMarker, draggable: true }).addTo(mapa);
    // const marker3 = L.marker(point3, { icon: customMarker, draggable: true }).addTo(mapa);]
    if (lat && lng) {
      if (!marker) {
        marker = new L.Marker(L.latLng(lat, lng), { icon: customMarker }); // set
        marker.addTo(mapa);
      }
    }
    const bounds = new L.LatLngBounds(point1, point2).extend(point3);
    mapa.fitBounds(bounds);

    if (!overlay) {
      overlay = new L.ImageOverlay.Rotated('/assets/mapanovo.png', point1, point2, point3, {
        opacity: 0.5,
        interactive: true,
      });
      overlay.addTo(mapa);
    }
  }, []);

  // eslint-disable-next-line new-cap
  const customMarker = new L.icon({
    iconUrl: 'https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

  mapa.on('click', function (e) {
    if (handleChange) {
      if (marker) {
        // check
        mapa.removeLayer(marker); // remove
      }
      // eslint-disable-next-line new-cap

      // const overlay = L.imageOverlay.rotated('/assets/mapa.png', point1, point2, point3, {
      //   opacity: 0.4,
      //   interactive: true,
      // });
      // overlay.addTo(mapa);
      marker = new L.Marker(e.latlng, { icon: customMarker }); // set
      marker.addTo(mapa);
      handleChange(e.latlng);
    }
  });
  // return null;
  return <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />;

  // const mapa = useMap();
  // const image = useRef('image');
  // useEffect(() => {
  //   if (
  //     image?.current !== undefined &&
  //     typeof image?.current !== 'string' &&
  //     image?.current !== null
  //   ) {
  //     // @ts-expect-error
  //     rotateImage(image?.current.getElement(), -12);
  //     console.log('teste');
  //   }
  //   console.log(mapa, 'teste');
  // }, [mapa.get]);
  // mapa.on('zoomend', function () {
  //   if (
  //     image?.current !== undefined &&
  //     typeof image?.current !== 'string' &&
  //     image?.current !== null
  //   ) {
  //     // @ts-expect-error
  //     rotateImage(image?.current.getElement(), -12);
  //     console.log('teste');
  //   }
  // });
  // function rotateImage(image, angle: number): void {
  //   // if (!originalTransformValue) {
  //   //   originalTransformValue = image.style.transform;
  //   // }
  //   // image.style.transform = `${originalTransformValue} rotateZ(${angle}deg)`;
  //   // image.style.transform = `rotateZ(${angle}deg)`;
  // }
  // return (
  //   <ImageOverlay
  //     url="/assets/mapa.png"
  //     bounds={[
  //       [-0.042501, -51.111581],
  //       [-0.0441, -51.111452],
  //       [-0.04248, -51.104039],
  //     ]}
  //     ref={image}
  //   >
  //     <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
  //   </ImageOverlay>
  // );
}

// const WrapperContainer = styled.div`
//   width: 100%;

//   .content {
//     width: 100%;

//     & div {
//       display: flex;
//     }

//     select:focus {
//       outline: none;
//     }

//     textarea:focus {
//       outline: none;
//     }

//     & input {
//       width: 100%;
//     }

//     & input:focus {
//       outline: none !important;
//     }

//     .inputfile {
//       width: 0.1px;
//       height: 0.1px;
//       opacity: 0;
//       overflow: hidden;
//       position: absolute;
//       z-index: -1;
//     }
//     .inputfile:focus + label {
//       outline: 1px dotted #000;
//       outline: -webkit-focus-ring-color auto 5px;
//     }
//     .inputfile + label * {
//       pointer-events: none;
//     }
//   }
// `;
