import React from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import { Container } from './sideBarMobile.styled';
import { organizador, useSidebarList } from './sidebarList';
import { useSideBarSelected } from './../../hooks/useSideBarSelected';

const MobileSideBar = ({ active }): JSX.Element => {
  const permission = useSidebarList();
  const { handleChangeSelected, sidebarSelectedItem } = useSideBarSelected();
  const closeSidebar = (): void => {
    active(false);
  };

  return (
    <Container left={active}>
      <FaTimes
        style={{
          marginTop: 60,
          marginLeft: 10,
        }}
        size={20}
        onClick={closeSidebar}
      />
      <img
        src="assets/IMAGE.png"
        width={170}
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 15,
        }}
        height={'auto'}
      />
      <div className="menu-section">
        <div style={{ marginTop: 20 }} />
        {permission.functions.map((item, i) => (
          <Link key={Number(i)} style={{ textDecoration: 'none' }} to={item.to}>
            <button
              className="list-menu"
              onClick={() => {
                // @ts-expect-error
                handleChangeSelected(item);
              }}
              // style={{
              //   background: selected?.title === item.title ? 'rgba(0,62,93, 0.3)' : '#FFF',
              // }}
            >
              {sidebarSelectedItem?.title === item.title && (
                <div
                  style={{
                    position: 'relative',
                    right: 0,
                    background: 'rgba(0,62,93)',
                    width: 10,
                    height: '100%',
                  }}
                />
              )}
              <div
                style={{
                  paddingLeft: sidebarSelectedItem?.title === item.title ? 10 : 20,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {item.icon} <h5 className="text"> {item.title}</h5>
              </div>
            </button>
          </Link>
        ))}
        <div className="title-section" />
      </div>
    </Container>
  );
};

export default MobileSideBar;
